import { ConfigAction, SetConfig, ConfigState } from './type';

const initialState: ConfigState = {
  hrisDomain: '',
};

export function configReducer(state = initialState, action: ConfigAction) {
  switch (action.type) {
    case SetConfig:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
