import React from 'react';
import { Input, Modal, Select } from 'shopee-ui-react';
import styles from './index.module.scss';

interface MemoModalProps {
  visible: boolean;
  memoList: string[];
  reason: string;
  remark: string;
  confirm: () => void;
  reasonChange: (value: string) => void;
  remarkChange: (value: string) => void;
  setVisible: (value: boolean) => void;
}

const MemoModal = ({
  visible = false,
  memoList = [],
  reason = '',
  remark = '',
  confirm,
  reasonChange,
  remarkChange,
  setVisible,
}: MemoModalProps) => {
  return (
    <Modal
      className={styles.memo_modal}
      title="Remarks"
      visible={visible}
      confirmText="Save"
      onConfirm={() => confirm()}
      onCancel={() => setVisible(false)}>
      <Select
        value={reason}
        onChange={value => reasonChange(value as string)}>
        {memoList.map((item, index) => (
          <Select.Option
            key={index}
            value={item}>
            {item}
          </Select.Option>
        ))}
      </Select>
      {reason === 'Others' ? (
        <Input
          className={styles.other_input}
          value={remark}
          placeholder="Others, please specify"
          maxLength={30}
          showWordLimit
          clearable
          onChange={remarkChange}
        />
      ) : null}
    </Modal>
  );
};

export default MemoModal;
