import axios, { AxiosRequestConfig, AxiosResponse, AxiosInstance } from 'axios';
import {
  requestInterceptors,
  handleReqError,
} from './interceptor/request.interceptor';
import {
  responseInterceptors,
  handleResponseError,
} from './interceptor/response.interceptor';

const axiosBaseConfig: AxiosRequestConfig = {
  timeout: 0,
  baseURL: '/api',
};

export type PromiseBase<T> = Promise<{
  code: number;
  message?: string;
  data: T;
}>;

type AxiosRequestInterceptor = (
  config: AxiosRequestConfig
) => AxiosRequestConfig | Promise<AxiosRequestConfig>;
type AxiosResponseInterceptor = (config: AxiosResponse) => any;

type CreateFetchOptions = {
  requestInterceptors?: AxiosRequestInterceptor[];
  responseInterceptors?: AxiosResponseInterceptor[];
  responseProcessor?: (response: AxiosResponse) => any;
};

// 创建 axios 实例
const createFetch = (
  axiosConfig: AxiosRequestConfig,
  options: CreateFetchOptions
) => {
  const instance = axios.create({
    ...axiosBaseConfig,
    ...axiosConfig,
  });

  // 按顺序使用 requestInterceptor
  options?.requestInterceptors?.forEach(interceptor => {
    instance.interceptors.request.use(interceptor, handleReqError);
  });

  // 按顺序使用 responseInterceptor
  options?.responseInterceptors?.forEach(interceptor => {
    instance.interceptors.response.use(interceptor, handleResponseError);
  });

  return instance;
};

const request = createFetch(
  {
    baseURL: axiosBaseConfig.baseURL,
  },
  {
    requestInterceptors: [
      requestInterceptors.camelToSnake,
      requestInterceptors.csrfHandler,
      requestInterceptors.xReqUserHandler,
    ],
    responseInterceptors: [responseInterceptors.handleResponse],
  }
);

export const fileRequest = createFetch(
  {
    baseURL: axiosBaseConfig.baseURL,
  },
  {
    requestInterceptors: [
      requestInterceptors.camelToSnake,
      requestInterceptors.csrfHandler,
      requestInterceptors.xReqUserHandler,
    ],
    responseInterceptors: [],
  }
);

export default request;
