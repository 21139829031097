export interface ConfigState {
  hrisDomain?: string;
}

export const SetConfig = 'SetConfig';

interface SetCofigAction {
  type: typeof SetConfig;
  payload: ConfigState;
}

export type ConfigAction = SetCofigAction;
