import { combineReducers, createStore } from 'redux';
import { userInfoReducer } from './userInfo/reducer';
import { configReducer } from './config';

export const rootReducer = combineReducers({
  userInfo: userInfoReducer,
  config: configReducer,
});

const store = createStore(rootReducer);

export default store;
