import React from 'react';
import styles from './index.module.scss';

interface NoPermissionProps {
  imageUrl: string;
  tip: string;
}

const NoPermission = ({ imageUrl = '', tip = '' }: NoPermissionProps) => {
  return (
    <div className={styles.permission_wrapper}>
      <img
        className={styles.permission_image}
        src={imageUrl}
      />
      <div className={styles.permission_tip}>{tip}</div>
    </div>
  );
};

export default NoPermission;
