import React from 'react';
import styles from './index.module.scss';
import { Button, DatePicker } from 'shopee-ui-react';
import { CalendarRange } from '@contants/index';
import dayjs from 'dayjs';

interface UserCalendarFilterProps {
  startDate?: Date;
  endDate?: Date;
  currentRange?: string;
  hasExportPermission?: boolean;
  hasFilterPermission?: boolean;
  startDateChange?: (value?: Date) => void;
  endDateChange?: (value?: Date) => void;
  rangeChange: (range: string) => void;
  onSubmit?: () => void;
  onExport?: () => void;
}

const UserCalendarFilter = ({
  startDate,
  endDate,
  currentRange,
  hasExportPermission,
  hasFilterPermission,
  startDateChange,
  endDateChange,
  rangeChange,
  onSubmit,
  onExport,
}: UserCalendarFilterProps) => {
  const handleClickRange = (range: string) => {
    rangeChange(range);
  };

  return (
    <div className={styles.filter_wrapper}>
      <div className={styles.legend_wrapper}>
        <div className={styles.legend_item}>
          <div className={`${styles.square} ${styles.red}`}></div>
          <span>Below Standard</span>
        </div>
        <div className={styles.legend_item}>
          <div className={`${styles.square} ${styles.green}`}></div>
          <span>Standard</span>
        </div>
        <div className={styles.legend_item}>
          <div className={`${styles.square} ${styles.blue}`}></div>
          <span>{`It's a Long Day`}</span>
        </div>
      </div>
      <div className={styles.right_wrapper}>
        <div className={styles.range_wrapper}>
          <div
            onClick={() => handleClickRange(CalendarRange.SevenDays)}
            className={
              currentRange === CalendarRange.SevenDays
                ? `${styles.range_item} ${styles.selected}`
                : styles.range_item
            }>
            7D
          </div>
          <div
            onClick={() => handleClickRange(CalendarRange.FourteenDays)}
            className={
              currentRange === CalendarRange.FourteenDays
                ? `${styles.range_item} ${styles.selected}`
                : styles.range_item
            }>
            14D
          </div>
          <div
            onClick={() => handleClickRange(CalendarRange.ThirtyDays)}
            className={
              currentRange === CalendarRange.ThirtyDays
                ? `${styles.range_item} ${styles.selected}`
                : styles.range_item
            }>
            30D
          </div>
        </div>
        <div className={styles.date_wrapper}>
          {hasExportPermission || hasFilterPermission ? (
            <>
              Date:
              <DatePicker
                placeholder="Choose a date/time"
                className={styles.start_date}
                type="date"
                clearable
                size="small"
                max={new Date()}
                value={startDate}
                format={value => {
                  return dayjs(value as Date).format('DD/MM/YYYY');
                }}
                onChange={(value: any) =>
                  startDateChange && startDateChange(value)
                }
              />
              <div className={styles.date_split}>-</div>
              <DatePicker
                placeholder="Choose a date/time"
                className={styles.end_date}
                type="date"
                clearable
                size="small"
                max={new Date()}
                value={endDate}
                format={value => {
                  return dayjs(value as Date).format('DD/MM/YYYY');
                }}
                onChange={(value: any) => endDateChange && endDateChange(value)}
              />
              {hasFilterPermission ? (
                <Button
                  type="primary"
                  size="small"
                  className={styles.submit_btn}
                  onClick={onSubmit}>
                  Submit
                </Button>
              ) : null}
              {hasExportPermission ? (
                <Button
                  type="primary"
                  size="small"
                  onClick={onExport}>
                  Export
                </Button>
              ) : null}
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default UserCalendarFilter;
