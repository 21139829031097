import { AttendanceGroupFeedResponse, DayInfo } from '@api/type';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import styles from './index.module.scss';
import { useSelector } from 'react-redux';
import { UserInfoState } from '@reducers/userInfo/type';
import { Popover, Spin } from 'shopee-ui-react';
import PopoverContent from './PopoverContent';
import { ConfigState } from '@reducers/config/type';

interface UserCalendarProps extends AttendanceGroupFeedResponse {
  hrisDomain?: string;
  loading?: boolean;
  loadingMore?: boolean;
  addExemption?: (staff: any) => void;
  openAddMemo?: (staffId: number, day: any) => void;
  loadMore?: () => void;
}

const UserCalendar = ({
  calender = [],
  attendance = [],
  hrisDomain = '',
  myAttendance = false,
  hasMore = false,
  loading = false,
  loadingMore = false,
  addExemption,
  openAddMemo,
  loadMore,
}: UserCalendarProps) => {
  const userInfo = useSelector(
    (store: { userInfo: UserInfoState }) => store.userInfo
  );
  const config = useSelector((store: { config: ConfigState }) => store.config);
  // id=atdcChartDataWrapper
  const chartDataWrapperRef = useRef<HTMLDivElement>(null);
  // id=atdcChartItemWp
  const chartItemWrapperRef = useRef<HTMLDivElement>(null);

  const [defaultContainerWidth, setDefaultContainerWidth] = useState(0);
  const [xpoi, setXpoi] = useState(0);
  const [attendanceContainerWidth, setAttendanceContainerWidth] = useState(0);
  const [hoverStaffs, setHoverStaffs] = useState<number[]>([]);

  const attendanceItemWidth = 22;
  const unitHeight = 3;

  // state

  const dayNumber = useMemo(() => {
    const number = calender.reduce((sum, current) => {
      return sum + current.days.length;
    }, 0);
    return number;
  }, [calender]);

  const barAllStyle: React.CSSProperties = useMemo(() => {
    return {
      left: xpoi,
      width: dayNumber * 22 + 1,
      textAlign: 'center',
      lineHeight: '30px',
      color: '#5c5c5c',
    };
  }, [xpoi, dayNumber]);

  const getCalendarStyle = (dayLength: number): React.CSSProperties => {
    return {
      width: 22 * dayLength - 1,
      borderRight: '1px solid #d9d9d9',
      marginRight: 0,
    };
  };

  const setDefaultWidth = () => {
    let displayNumber = 10;
    if (window.innerWidth - 440 > attendanceItemWidth * displayNumber) {
      displayNumber = Math.floor(
        (window.innerWidth - 440) / attendanceItemWidth
      );
    }

    setDefaultContainerWidth(attendanceItemWidth * displayNumber);
  };

  const setChartWrapperHeight = () => {
    if (chartDataWrapperRef.current) {
      chartDataWrapperRef.current.style.height = `${
        window.innerHeight - 330
      }px`;
    }
  };

  const handleMouseOver = (staff: any) => {
    if (!hoverStaffs.includes(staff.id)) {
      setHoverStaffs([...hoverStaffs, staff.id]);
    }
  };

  const handleMouseOut = (staff: any) => {
    const index = hoverStaffs.indexOf(staff.id);
    if (index > -1) {
      hoverStaffs.splice(index, 1);
      setHoverStaffs([...hoverStaffs]);
    }
  };

  const getBarWrapperClassName = (day: DayInfo) => {
    if (
      day.isWeekend ||
      day.isHoliday ||
      day.isLeave ||
      day.isOnBusinessTravel
    ) {
      return styles.bar_gray;
    }
    return 'className';
  };

  const getTipName = (day: DayInfo, top: string) => {
    let tip = '';
    if (day.isOnBusinessTravel === 1) {
      tip = 'T';
    }
    if (day.isWeekend) {
      tip = 'S';
    }
    if (day.isHoliday) {
      tip = 'P';
    }
    if (day.isLeave === 1) {
      tip = 'L';
    }
    if (tip) {
      return (
        <div
          className={styles.script_type_icon}
          style={{ top }}>
          {tip}
        </div>
      );
    }

    return null;
  };

  const getBarClassName = (day: DayInfo) => {
    let className = '';
    if (day.periodType == '0') {
      className = `${className} ${styles.red}`;
    } else if (day.periodType == '1') {
      className = `${className} ${styles.green}`;
    } else if (day.periodType == '2') {
      className = `${className} ${styles.blue}`;
    }
    return className;
  };

  const toLastPage = () => {
    if (xpoi >= 0) {
      return;
    }
    setXpoi(xpoi + defaultContainerWidth);
  };

  const toNextPage = () => {
    if (xpoi <= defaultContainerWidth - attendanceContainerWidth) {
      return;
    }
    setXpoi(xpoi - defaultContainerWidth);
  };

  const handleLoadMore = () => {
    loadMore && loadMore();
  };

  const resizeEvent = () => {
    setXpoi(0);
    setDefaultWidth();
    setChartWrapperHeight();
  };

  useEffect(() => {
    setDefaultWidth();
    setChartWrapperHeight();
    window.addEventListener('resize', resizeEvent);

    return () => {
      window.removeEventListener('resize', resizeEvent);
    };
  }, []);

  useEffect(() => {
    const scrollEvent = () => {
      if (chartDataWrapperRef.current && chartItemWrapperRef.current) {
        const atdcChartDataWrapper = chartDataWrapperRef.current;
        const atdcChartItemWp = chartItemWrapperRef.current;
        if (
          hasMore &&
          loadMore &&
          !loadingMore &&
          atdcChartDataWrapper.scrollTop >=
            atdcChartItemWp.offsetHeight -
              atdcChartDataWrapper.offsetHeight -
              10
        ) {
          handleLoadMore();
        }
      }
    };
    setDefaultWidth();
    setChartWrapperHeight();

    if (chartDataWrapperRef.current) {
      chartDataWrapperRef.current.addEventListener('scroll', scrollEvent);
    }

    return () => {
      if (chartDataWrapperRef.current) {
        chartDataWrapperRef.current.removeEventListener('scroll', scrollEvent);
      }
    };
  }, [hasMore, loadMore, loadingMore, attendance]);

  useEffect(() => {
    setAttendanceContainerWidth(attendanceItemWidth * dayNumber);
  }, [attendance, dayNumber]);

  if (loading && !loadingMore) {
    return (
      <div className={styles.loading_data}>
        <Spin />
      </div>
    );
  }

  return (
    <div className={styles.chart_wrapper}>
      <div className={styles.chart}>
        {/* month */}
        <div
          className={`${styles.chart_item} ${styles.clearfix}`}
          style={{ height: 30 }}>
          <div
            className={`${styles.chart_username} ${styles.fl}`}
            style={{ paddingTop: 0, height: '100%' }}></div>
          <div className={`${styles.chart_detail} ${styles.fl}`}>
            <div className={`${styles.content} ${styles.fl}`}>
              <div className={`${styles.last_page} ${styles.fl}`}></div>
              <div
                style={{ width: defaultContainerWidth }}
                className={`${styles.bar_container} ${styles.fl}`}>
                <div
                  style={barAllStyle}
                  className={`${styles.bar_all} ${styles.clearfix}`}>
                  {calender.map((item, index) => (
                    <div
                      className={`${styles.bar_wrapper} ${styles.fl}`}
                      key={index}
                      style={getCalendarStyle(item.days.length)}>
                      {item.days.length > 1 ? <span>{item.month}</span> : null}
                    </div>
                  ))}
                </div>
              </div>
              <div className={`${styles.next_page} ${styles.fl}`}></div>
            </div>
          </div>
        </div>
        {/* day */}
        <div
          className={`${styles.chart_item} ${styles.clearfix}`}
          style={{ height: 30 }}>
          <div
            className={`${styles.chart_username} ${styles.fl}`}
            style={{ paddingTop: 0, height: '100%' }}></div>
          <div className={`${styles.chart_detail} ${styles.fl}`}>
            <div className={`${styles.content} ${styles.fl}`}>
              <div className={`${styles.last_page} ${styles.fl}`}>
                <div
                  onClick={toLastPage}
                  className={
                    xpoi < 0
                      ? `sprite-page-last sprite ${styles['sprite-page-last']}`
                      : `sprite-page-last-disable sprite ${styles['sprite-page-last-disable']}`
                  }></div>
              </div>
              <div
                style={{ width: defaultContainerWidth }}
                className={`${styles.bar_container} ${styles.fl}`}>
                <div
                  style={barAllStyle}
                  className={`${styles.bar_all} ${styles.clearfix}`}>
                  {calender.map((item, index) => (
                    <div
                      className={`${styles.bar_wrapper} ${styles.fl}`}
                      key={index}
                      style={getCalendarStyle(item.days.length)}>
                      {item.days.map((day, dayIndex) => (
                        <div
                          className={`${styles.bar_wrapper} ${styles.fl}`}
                          key={dayIndex}
                          style={{
                            marginRight:
                              dayIndex === item.days.length - 1 ? 0 : 1,
                          }}>
                          {day.isWeekend ? null : <span>{day.day}</span>}
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              </div>
              <div className={`${styles.next_page} ${styles.fl}`}>
                <div
                  onClick={toNextPage}
                  className={
                    attendanceContainerWidth > defaultContainerWidth &&
                    (xpoi > defaultContainerWidth - attendanceContainerWidth ||
                      !xpoi)
                      ? `sprite-page-next sprite ${styles['sprite-page-next']}`
                      : `sprite-page-next-disable sprite ${styles['sprite-page-next-disable']}`
                  }></div>
              </div>
            </div>
          </div>
        </div>

        {/* data */}
        <div className={styles.chart_data_outer_wrapper}>
          <div
            className={styles.chart_data_wrapper}
            ref={chartDataWrapperRef}>
            <div ref={chartItemWrapperRef}>
              {attendance.map((staff, index) => (
                <div
                  key={index}
                  className={
                    userInfo.pk === staff.id
                      ? `${styles.chart_item} ${styles.clearfix} ${styles.is_myself}`
                      : `${styles.chart_item} ${styles.clearfix}`
                  }
                  style={{ height: unitHeight * Number(staff.hours) + 4 }}>
                  <div className={`${styles.chart_username} ${styles.fl}`}>
                    <span>
                      <a
                        rel="noreferrer"
                        href={
                          userInfo.fields.canAccessProfile
                            ? `${config.hrisDomain}/#/detail/${staff.id}`
                            : ''
                        }
                        target="_blank">
                        <em>{staff.name}</em>
                      </a>
                    </span>
                  </div>
                  <div className={`${styles.chart_detail} ${styles.fl}`}>
                    <div className={`${styles.content} ${styles.fl}`}>
                      <div
                        className={`${styles.last_page} ${styles.fl}`}
                        onMouseOver={() => handleMouseOver(staff)}
                        onMouseOut={() => handleMouseOut(staff)}>
                        {userInfo.fields.canExempt &&
                        hoverStaffs.includes(staff.id) &&
                        !staff.exempt ? (
                          <div
                            // href="javascript:;"
                            className={`sprite-bag sprite ${styles['sprite-bag']}`}
                            onClick={() =>
                              addExemption && addExemption(staff)
                            }></div>
                        ) : null}
                        {userInfo.fields.canExempt && staff.exempt ? (
                          <div
                            // href="javascript:;"
                            className={`sprite-bag-exempt sprite ${styles['sprite-bag-exempt']}`}
                            onClick={() =>
                              addExemption && addExemption(staff)
                            }></div>
                        ) : null}
                        {!userInfo.fields.canExempt && staff.exempt ? (
                          <div
                            // href="javascript:;"
                            // style={{ display:  ? 'block' : 'none' }}
                            className={`sprite-bag-exempt sprite-bag-exempt-disable sprite ${styles['sprite-bag-exempt']} ${styles['sprite-bag-exempt-disable']}`}
                            onClick={() =>
                              addExemption && addExemption(staff)
                            }></div>
                        ) : null}
                      </div>

                      {/* bar chart */}
                      <div
                        className={`${styles.bar_container} ${styles.fl}`}
                        style={{ width: defaultContainerWidth }}>
                        <div
                          className={`${styles.bar_all} ${styles.clearfix}`}
                          style={{
                            left: xpoi,
                            width: staff.days.length * 31 + 1,
                          }}>
                          {staff.days.map((day, index) => (
                            // 增加popover
                            <Popover
                              overlayInnerContentClassName={
                                styles.popover_content
                              }
                              key={index}
                              placement="bottom"
                              trigger="hover"
                              content={
                                <PopoverContent
                                  {...day}
                                  id={staff.id}
                                  exempt={staff.exempt}
                                />
                              }>
                              <div
                                key={index}
                                style={{
                                  backgroundPosition: `center ${
                                    unitHeight * Number(staff.hours || 0) + 4 >
                                    30
                                      ? unitHeight * Number(staff.hours || 0) +
                                        4 -
                                        16
                                      : 14
                                  }px`,
                                }}
                                onClick={() =>
                                  openAddMemo && openAddMemo(staff.id, day)
                                }
                                className={`${styles.bar_wrapper} ${
                                  styles.fl
                                } ${getBarWrapperClassName(day)}`}>
                                {day.hours !== '' ? (
                                  <div
                                    style={{
                                      height:
                                        day.isLeave === 2
                                          ? unitHeight *
                                            (Number(day.hours) * 1 + 5.5)
                                          : unitHeight * Number(day.hours),
                                    }}
                                    className={`${styles.bar} ${getBarClassName(
                                      day
                                    )}`}>
                                    {day.isLeave === 2 ? (
                                      <div
                                        style={{
                                          height: unitHeight * 5.5,
                                          backgroundPosition: `center ${
                                            (unitHeight * 5.5) / 2 - 2
                                          }px`,
                                        }}
                                        className={styles.bar_half}></div>
                                    ) : null}
                                  </div>
                                ) : null}
                                {!day.today &&
                                !staff.exempt &&
                                !day.isWeekend &&
                                !day.isHoliday &&
                                !day.isLeave &&
                                !day.isOnBusinessTravel &&
                                day.in === '' &&
                                day.out === '' ? (
                                  <div
                                    className={`sprite-missing sprite ${styles['sprite-missing']}`}></div>
                                ) : null}
                                {!day.today &&
                                !staff.exempt &&
                                !day.isWeekend &&
                                !day.isHoliday &&
                                !day.isLeave &&
                                !day.isOnBusinessTravel &&
                                ((day.in === '' && day.out !== '') ||
                                  (day.in !== '' && day.out === '')) ? (
                                  <div
                                    className={`sprite-incomplete sprite ${styles['sprite-incomplete']}`}></div>
                                ) : null}
                                {!day.today &&
                                staff.exempt &&
                                !day.isWeekend &&
                                !day.isHoliday &&
                                !day.isLeave &&
                                !day.isOnBusinessTravel &&
                                (day.in === '' || day.out === '') ? (
                                  <div className={styles.exempt_line}>✓</div>
                                ) : null}
                                {day.today &&
                                myAttendance &&
                                !day.isWeekend &&
                                !day.isHoliday &&
                                !day.isLeave &&
                                !day.isOnBusinessTravel &&
                                (day.in === '' || day.out === '') ? (
                                  <div
                                    className={`${styles.bar} ${styles.dashed}`}></div>
                                ) : null}
                                {day.in !== '' && day.out !== '' && day.memo ? (
                                  <div
                                    className={`${styles.dot} ${styles.dot_white}`}></div>
                                ) : null}
                                {(day.in === '' ||
                                  day.out === '' ||
                                  day.isLeave === 2) &&
                                day.memo ? (
                                  <div
                                    className={`${styles.dot} ${styles.dot_red}`}></div>
                                ) : null}
                                {getTipName(
                                  day,
                                  `${
                                    unitHeight * Number(staff.hours || 0) + 4 >
                                    30
                                      ? unitHeight * Number(staff.hours || 0) +
                                        4 -
                                        21
                                      : 14
                                  }px`
                                )}
                              </div>
                            </Popover>
                          ))}
                        </div>
                      </div>

                      <div className={`${styles.next_page} ${styles.fl}`}></div>
                    </div>
                  </div>
                </div>
              ))}

              {loadingMore ? (
                <div className={styles.loading_more}>
                  <Spin />
                </div>
              ) : null}

              {/* load more */}
              {!loadingMore && hasMore ? (
                <div className={styles.load_more}>
                  <div
                    className={styles.load_more_btn}
                    onClick={handleLoadMore}>
                    Load More
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserCalendar;
