import { MdapSdk } from '@mdap/javascript-sdk';
import APIPlugin from '@mdap/sdk-plugin-api';
import ResourcePlugin from '@mdap/sdk-plugin-resource';
import PagePerfPlugin from '@mdap/sdk-plugin-performance';
import ExceptionPlugin from '@mdap/sdk-plugin-exception';
import pkg from '../../package.json';
import { AxiosResponse } from 'axios';
import store from '@reducers/index';

type ENV = 'test' | 'live';

const appName = 'attendance-pc-web';
const mdapSecretKeys = {
  live: 'eb70a36159fbf51d6a8a2bef0af81dd0153315ca801ef836389bab593316da9e',
  test: '4e8f64d2c977434b1d1e014624f4fb0ae9487e37f56cb1def1e8d18a10de4608',
};

console.log('process.env.REACT_APP_ENV', process.env.REACT_APP_ENV);
const environment = (process.env.REACT_APP_ENV as ENV) || 'test';
const region = 'sg';

// 初始化SDK SDK initialization
const sdk = new MdapSdk({
  // mdap 应用id application id
  app_name: appName,
  secret_key: mdapSecretKeys[environment],
  // reporting env: test | staging | uat | live
  // 上报环境: test | staging | uat | live
  // environment值为 ‘live’ 时，数据会上报到正服环境 https://mdap.shopee.io， 为其他值时数据会上报到测服  https://mdap.exp.shopee.io
  environment,
  // 上报地区/Reporting Region | sg | tw | ph | th | id | my | br | mx | ...
  region,
  // 是否开启调试日志/Toggle of debug logger | default - false
  logger: false,
  // 业务应用版本 business application version
  app_version: `v${pkg.version}`,
  // sampling rate 采样率 - default 0.1
  sample: 1,
});

// api请求监控插件  Plugin of api request
export const apiPlugin = new APIPlugin({
  path: '/api',
});

// 静态资源加载监控插件 Plugin of resource loading
const resourcePlugin = new ResourcePlugin({
  path: ['/scripts', '/images', '/styles'],
});

// 页面性能监控插件 Plugin of page performance
const pagePerfPlugin = new PagePerfPlugin({
  path: ['/'],
});

export const exceptionPlugin = new ExceptionPlugin({
  interval: 1000,
});

sdk.use(exceptionPlugin);
sdk.use(apiPlugin);
sdk.use(resourcePlugin);
sdk.use(pagePerfPlugin);

export const uploadApiError = function (response: AxiosResponse) {
  if (exceptionPlugin) {
    const config = response.config;
    const status = response.status;
    let extraInfo = {
      url: config.url,
      method: config.method,
      status,
    } as any;
    let errorName = '';
    if (status !== 200) {
      extraInfo = {
        ...extraInfo,
        responseData: response.data,
      };
      errorName = config.url + ' api status error';
    } else if (status === 200 && response.data.ret && response.data.ret !== 0) {
      errorName = config.url + ' api response data error';
    }

    const userInfo = store.getState().userInfo;

    if (errorName && userInfo.pk) {
      exceptionPlugin.capture({
        message: new Date().toString(),
        data: {
          name: errorName,
          level: 'info',
          data_field: {
            pk: `${userInfo.pk}`,
            userName: userInfo.fields.fullName,
          },
          extra: {
            data: extraInfo,
          },
        },
      });
    }
  }
};

export default {
  exceptionPlugin,
};
