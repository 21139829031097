import { uploadApiError } from '@plugins/mdap';
import { logout } from '@util/index';
import { AxiosResponse } from 'axios';
import { camelizeKeys } from 'humps';

class BusinessError extends Error {
  code: number;
  status: number;

  constructor(code: number, message: string, status: number) {
    super(message);
    this.code = code;
    this.status = status;
  }
}

export const handleResponse = (response: AxiosResponse<any>) => {
  const { data } = response;

  if (data.ret && data.ret !== 0) {
    uploadApiError(response);
  }

  return camelizeKeys(data);
};

export const handleResponseError = (error: any) => {
  console.log(
    '>>>>> api failed return: ',
    error.config?.method,
    error.config?.url,
    JSON.stringify(error.data),
    error.message
  );

  const { response } = error;

  uploadApiError(response);

  if (!response || !response.status) {
    throw new BusinessError(-8888, 'Network error. Please try again.', 0);
  }

  const { status, data = {} } = response;
  if (status === 403) {
    // 处理登陆问题
    const email =
      decodeURIComponent(
        (new RegExp('[?|&]' + 'email' + '=' + '([^&;]+?)(&|#|;|$)').exec(
          location.search
        ) || [null, ''])[1].replace(/\+/g, '%20')
      ) || '';
    logout(email);
  } else {
    const { message } = data;
    const defaultMessage =
      'Unable to process your request. Please contact your HR if problem persists.' +
      `(${'Status:' + status})`;
    const msg = message || defaultMessage;
    throw new BusinessError(-9999, msg, status);
  }
};

// 通用 interceptor 可以加到这里
export const responseInterceptors = {
  handleResponse,
};
