import { UserInfoState } from '@reducers/userInfo/type';
import request, { PromiseBase, fileRequest } from '../request';
import {
  HrFunctionItem,
  TeamScopeItem,
  WhiteHrDetail,
  WhitelistResponse,
  WhitelistUserResponse,
} from './type';

export const getWhiteListUser = (params: {
  keyword: string;
}): PromiseBase<WhitelistUserResponse> => {
  return request.post(
    '/attendance/v1/admin/whitelisted-hr/staff/search/',
    params
  );
};

export const getWhitelistTeamScope = (): PromiseBase<TeamScopeItem[]> => {
  return request.get('/attendance/v1/admin/whitelisted-hr/org/list/');
};

export const getWhitelist = (params: {
  keyword: string;
  teamIds: number[];
  pageNum: number;
  pageSize: number;
}): PromiseBase<WhitelistResponse> => {
  return request.post('/attendance/v1/admin/whitelisted-hr/list/', params);
};

export const getWhiteHrDetail = (params: {
  hrUserId: number;
}): PromiseBase<WhiteHrDetail> => {
  return request.get('/attendance/v1/admin/whitelisted-hr/detail/', { params });
};

export const addWhiteUser = (params: {
  hrEmail: string;
  teamIds: number[];
}): PromiseBase<any> => {
  return request.post('/attendance/v1/admin/whitelisted-hr/add/', params);
};

export const editWhiteUser = (params: {
  hrUserId: number;
  teamIds: number[];
}): PromiseBase<any> => {
  return request.post('/attendance/v1/admin/whitelisted-hr/edit/', params);
};

export const deleteWhiteUser = (params: {
  hrUserId: number;
}): PromiseBase<any> => {
  return request.post('/attendance/v1/admin/whitelisted-hr/delete/', params);
};
