import { createBrowserRouter, redirect } from 'react-router-dom';
import { Router } from '@remix-run/router';
import NoPermission from '@components/NoPermission';
import {
  getPermissionConfig,
  notificationConfirm,
  postGroupList,
} from '@api/index';
import { ErrorCode } from '@contants/errorCode';
import React from 'react';
import { logout } from '@util/index';
import { Modal, Toast } from 'shopee-ui-react';
import MyAttendance from '@pages/MyAttendance';
import MyTeam from '@pages/MyTeam';
import AttendanceReport from '@pages/AttendanceReport';
import noPermissionImage from '@assets/sprites/no-permission.png';
import defaultPageImage from '@assets/sprites/default.png';
import StaffAttendance from '@pages/StaffAttendance';
import HrFunction from '@pages/HrFunction';
import HrFunctionDetail from '@pages/HrFunction/Detail';

export interface RouteInfo {
  path: string;
  component: React.ReactNode;
  loader?: any;
}

export interface SidebarInfo {
  path: string;
  name?: string;
}

export const routerMap = {
  loginDenied: {
    path: '/login-denied',
    component: (
      <NoPermission
        imageUrl={noPermissionImage}
        tip="Login Denied"
      />
    ),
  },
  defalutPage: {
    path: '/default-page',
    component: (
      <NoPermission
        imageUrl={defaultPageImage}
        tip={`Please refer to the "Attendance" mini-app in your Seatalk mobile app for "My Attendance" and "My Team" information`}
      />
    ),
  },
  myAttendance: {
    path: '/attendance/:feedId/:defaultRange/:showExport/:showFilter/:name',
    component: <MyAttendance />,
  },
  myTeam: {
    path: '/my-team',
    component: <MyTeam />,
  },
  staffAttendance: {
    path: '/staff-attendance',
    component: <StaffAttendance />,
  },
  attendanceReport: {
    path: '/attendance-report',
    component: <AttendanceReport />,
  },
  hrFunction: {
    path: '/hr-function/list',
    component: <HrFunction />,
  },
  hrFunctionDetail: {
    path: '/hr-function/detail/:id',
    component: <HrFunctionDetail />,
  },
};

const routes = [
  {
    label: 'Attendance',
    isLabel: true,
    iconClassName: 'sprite-label sprite',
    children: [
      {
        isAttendancePage: true,
        path: routerMap.myAttendance.path,
        component: routerMap.myAttendance.component,
      },
      {
        isAttendanceReportPage: true,
        path: routerMap.attendanceReport.path,
        component: routerMap.attendanceReport.component,
        label: 'Attendance Report',
      },
    ],
  },
  {
    label: 'Configuration',
    isLabel: true,
    iconClassName: 'sprite-configuration-icon sprite',
    children: [
      {
        isHrFunction: true,
        path: routerMap.hrFunction.path,
        component: routerMap.hrFunction.component,
        label: 'HR Data Access',
      },
      {
        isHrFunction: true,
        noShowInMenu: true,
        path: routerMap.hrFunctionDetail.path,
        component: routerMap.hrFunctionDetail.component,
      },
    ],
  },
  {
    isSearchStaff: true,
    path: routerMap.staffAttendance.path,
    component: routerMap.staffAttendance.component,
    noShowInMenu: true,
  },
];

const resolveRoutesInfo = (
  routerConfig: any[],
  hrPermission: boolean,
  userPermission: boolean,
  hrFunctionPermission: boolean
) => {
  let routesInfo: any[] = [];
  routerConfig.forEach(route => {
    if (route.isAttendancePage && userPermission) {
      routesInfo.push(route);
    } else if (
      (route.isAttendanceReportPage || route.isSearchStaff) &&
      hrPermission
    ) {
      routesInfo.push(route);
    } else if (route.isHrFunction && hrFunctionPermission) {
      routesInfo.push(route);
    }

    if (route.children) {
      routesInfo = [
        ...routesInfo,
        ...resolveRoutesInfo(
          route.children,
          hrPermission,
          userPermission,
          hrFunctionPermission
        ),
      ];
    }
  });
  return routesInfo;
};

const resolveSideBarInfo = (
  routerConfig: any[],
  hrPermission: boolean,
  userPermission: boolean,
  hrFunctionPermission: boolean,
  attendanceGroup: any[]
) => {
  const sidebars: any[] = [];
  routerConfig.forEach(route => {
    const menuItem = {} as any;
    if (route.isLabel) {
      menuItem.label = route.label;
    }
    if (route.iconClassName) {
      menuItem.iconClassName = route.iconClassName;
    }
    if (route.children) {
      menuItem.children = [];
      route.children.forEach((child: any) => {
        if (child.noShowInMenu) return;

        if (child.isAttendancePage && userPermission) {
          attendanceGroup.forEach(item => {
            menuItem.children.push({
              label: item.name,
              path: `/attendance/${item.id}/${item.defaultRange}/${Number(
                item.permission.showExport
              )}/${Number(item.permission.showFilter)}/${item.name.replaceAll(
                ' ',
                '-'
              )}`,
            });
          });
        } else if (child.isAttendanceReportPage && hrPermission) {
          menuItem.children.push({
            label: child.label,
            path: child.path,
          });
        } else if (child.isHrFunction && hrFunctionPermission) {
          menuItem.children.push({
            label: child.label,
            path: child.path,
          });
        }
      });
    }

    sidebars.push(menuItem);
  });
  return sidebars;
};

export const generationRoutesInfo = async (): Promise<{
  routers: RouteInfo[];
  sidebars: SidebarInfo[];
  loginDenied?: boolean;
  defaultPage?: boolean;
  hrPermission?: boolean;
}> => {
  try {
    const { code, data, message } = await getPermissionConfig();

    if (code === ErrorCode.NoLoginDenied) {
      return {
        routers: [routerMap.loginDenied],
        sidebars: [
          {
            path: routerMap.loginDenied.path,
          },
        ],
        loginDenied: true,
      };
    } else if (code === ErrorCode.NoLogin) {
      Toast.error(message);
      logout();
      return {
        routers: [],
        sidebars: [],
      };
    }

    // 显示default page
    if (data.notificationType === 1) {
      return {
        routers: [routerMap.defalutPage],
        sidebars: [
          {
            path: routerMap.defalutPage.path,
          },
        ],
        defaultPage: true,
      };
    } else if (data.notificationType === 2) {
      Modal.alarm({
        title: 'Notification',
        content:
          'Please refer to the "Attendance" mini-app in your Seatalk mobile app for "My Attendance" and "My Team" information',
        onConfirm: () => {
          notificationConfirm();
        },
      });
    }

    const hrPermission = Boolean(data.functionConfig[0].hasPerm);
    const userPermission = Boolean(data.functionConfig[1].hasPerm);
    const adminPermission = Boolean(data.functionConfig[2].hasPerm);
    // 当前My Attendance 和 My Team通过group接口以及config接口控制权限, Attendance Report和Search Staff只通过config来控制权限
    const { groups } = await postGroupList();

    const routers: any = resolveRoutesInfo(
      routes,
      hrPermission,
      userPermission,
      adminPermission
    );
    const sidebars: any = resolveSideBarInfo(
      routes,
      hrPermission,
      userPermission,
      adminPermission,
      groups
    );

    return {
      routers,
      sidebars,
      hrPermission,
    };
  } catch {
    return {
      routers: [],
      sidebars: [],
    };
  }
};
