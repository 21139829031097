import React, { useEffect, useMemo, useState } from 'react';
import logo from './logo.svg';
import styles from './App.module.scss';
import Header from '@components/Header/index';
import {
  BrowserRouter,
  createBrowserRouter,
  Navigate,
  Route,
  Router,
  RouterProvider,
  Routes,
} from 'react-router-dom';
import MyAttendance from './pages/MyAttendance';
import MyTeam from './pages/MyTeam';
import Navbar from '@components/Navbar';
import Sidebar, { MenuListItem } from '@components/Sidebar';
import AttendanceReport from '@pages/AttendanceReport';
import {
  getHrisDomain,
  getMenuBar,
  getPermissionConfig,
  getUserInfo,
} from '@api/index';
import { generationRoutesInfo } from './router';
import { Button, Spin } from 'shopee-ui-react';
import 'shopee-ui-react/src/styles/index.scss';
import store from '@reducers/index';
import { Provider, useSelector } from 'react-redux';
import { SetUserInfo } from '@reducers/userInfo/type';
import { SetConfig } from '@reducers/config/type';
import { updateGlobalConfig } from '@shopee-ee/monitor-js-sdk';

function App() {
  const [routes, setRoutes] = useState<
    {
      path: string;
      component: React.ReactNode;
    }[]
  >([]);

  const [menuList, setMenuList] = useState<MenuListItem[]>([]);

  const [menuBar, setMenuBar] = useState<any>([]);

  const [hrisDomain, setHrisDomain] = useState('');

  const [loading, setLoading] = useState(true);

  const [noPermission, setNoPermission] = useState(false);

  const [hrPermission, setHrPermission] = useState(false);

  useEffect(() => {
    void (async () => {
      const { routers, sidebars, loginDenied, hrPermission, defaultPage } =
        await generationRoutesInfo();
      const userInfo = await getUserInfo();
      updateGlobalConfig({ userID: userInfo[0]?.fields?.email });
      store.dispatch({ type: SetUserInfo, payload: userInfo[0] });

      const { menu } = await getMenuBar();
      const { hrisDomain } = await getHrisDomain();

      store.dispatch({ type: SetConfig, payload: { hrisDomain } });

      setNoPermission(Boolean(loginDenied || defaultPage));
      setHrisDomain(hrisDomain);
      setMenuBar(menu);
      setMenuList(sidebars);
      setHrPermission(hrPermission || false);
      setRoutes(routers);
      setLoading(false);
    })();
  }, []);

  const userInfo = useMemo(() => {
    return store.getState().userInfo;
  }, [store.getState().userInfo]);

  const redirectPath = useMemo(() => {
    let url = '';
    for (let i = 0; i < menuList.length; i++) {
      const menu = menuList[i];
      if (menu.path) {
        url = menu.path;
        break;
      }
      if (menu.children) {
        for (let j = 0; j < menu.children.length; j++) {
          const subMenu = menu.children[j];
          if (subMenu.path) {
            url = subMenu.path;
            break;
          }
        }
      }
      if (url) break;
    }
    return url;
  }, [menuList]);

  return (
    <Provider store={store}>
      <div className={styles.out_wrapper}>
        <Header
          username={userInfo.fields.fullName}
          photoUrl={userInfo.fields.photo}
          hrisUrl={hrisDomain}
          showVnLogo={userInfo.fields.vnLogo}
        />
        <Navbar menuList={menuBar} />
        <div className={styles.content}>
          {loading ? (
            <div className={styles.loading_wrapper}>
              <Spin />
            </div>
          ) : (
            <BrowserRouter>
              {noPermission ? null : (
                <Sidebar
                  menuList={menuList}
                  hrPermission={hrPermission}
                />
              )}
              <Routes>
                {routes.map((router, index) => {
                  return (
                    <Route
                      key={index}
                      path={router.path}
                      element={router.component}
                    />
                  );
                })}
                {menuList[0] ? (
                  <Route
                    path="*"
                    element={<Navigate to={redirectPath} />}
                  />
                ) : null}
              </Routes>
            </BrowserRouter>
          )}
        </div>
      </div>
    </Provider>
  );
}

export default App;
