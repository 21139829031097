import { UserInfoState } from '@reducers/userInfo/type';
import request, { PromiseBase, fileRequest } from './request';
import {
  AddExemptionResponse,
  AddMemoResponse,
  AttendanceGroupFeedResponse,
  AttendanceMemoConfigResponse,
  GetAttendanceReportConfigResponse,
  GetAttendanceReportParams,
  GetAttendanceReportResponse,
  MenuBarResponse,
  PermissionConfig,
  SearchStaffsResponse,
} from './type';

export const getPermissionConfig = (): PromiseBase<PermissionConfig> => {
  return request.get('/attendance/v1/user/config/');
};

export const getUserInfo = (): Promise<UserInfoState[]> => {
  return request.get('/login_staff/1/');
};

export const getHrisDomain = (): Promise<{ hrisDomain: string }> => {
  return request.get('/index/?target=others');
};

export const getAttendanceGroupFeeds = (params: {
  days: string;
  from: number;
  id: number;
}): Promise<AttendanceGroupFeedResponse> => {
  return request.post('/attendance/group/feeds/', params);
};

export const getAttendanceStaffFeeds = (params: {
  days: string;
  from: number;
  staffs: number[];
}): Promise<AttendanceGroupFeedResponse> => {
  return request.post('/attendance/staff/feeds/', params);
};

export const getAttendanceMemoConfig =
  (): Promise<AttendanceMemoConfigResponse> => {
    return request.post('/attendance/config/', {});
  };

export const getMenuBar = (): Promise<MenuBarResponse> => {
  return request.get('/menu_bar/');
};

export const addExemption = (params: {
  id: number;
  op: number;
}): Promise<AddExemptionResponse> => {
  return request.post('/attendance/staff/exempt/', params);
};

export const addMemo = (params: {
  id: number;
  memo: string;
  day: string;
}): Promise<AddMemoResponse> => {
  return request.post('/attendance/feed/memo/', params);
};

export const serachStaffs = (params: {
  keyword: string;
}): Promise<SearchStaffsResponse> => {
  return request.post('/attendance/staff/filter/', params);
};

export const getAttendanceReportConfig =
  (): PromiseBase<GetAttendanceReportConfigResponse> => {
    return request.get('/attendance/v1/hr/report/attendance/config/');
  };

export const getAttendanceReportList = (
  params: GetAttendanceReportParams
): PromiseBase<GetAttendanceReportResponse> => {
  return request.post('/attendance/v1/hr/report/attendance/list/', params);
};

export const exportAttendanceReport = (
  params: GetAttendanceReportParams & { attendanceIds: number[] }
) => {
  return fileRequest.post(
    '/attendance/v1/hr/report/attendance/list/export/',
    params,
    { responseType: 'arraybuffer' }
  );
};

export const postGroupList = (): Promise<{
  groups: {
    name: string;
    defaultRange: number;
    permission: {
      showExport: boolean;
      showFilter: boolean;
    };
    staffId: number;
    staffs: number[];
    id: number;
  }[];
}> => {
  return request.post('/attendance/group/list/', {});
};

export const notificationConfirm = (): Promise<any> => {
  return request.post('/attendance/v1/user/notification/confirm/', {});
};
