import {
  MonitorJsSdk,
  SDKPluginApi,
  SDKPluginException,
  SDKPluginPerformance,
  SDKPluginResource,
  markBizMetricsEnd,
  markBizMetricsStart,
} from '@shopee-ee/monitor-js-sdk';

const sdk = new MonitorJsSdk({
  projectID: 'attendance',
  userID: '',
  env: process.env.REACT_APP_ENV || 'test',
  source: 'web',
});

const apiPlugin = new SDKPluginApi({
  paths: ['/api'],
});
const exceptionPlugin = new SDKPluginException();
const performancePlugin = new SDKPluginPerformance();
const resourcePlugin = new SDKPluginResource();

sdk.use(apiPlugin);
sdk.use(exceptionPlugin);
sdk.use(performancePlugin);
sdk.use(resourcePlugin);

export { markBizMetricsEnd as markBizEnd, markBizMetricsStart as markBizStart };
