import dayjs from 'dayjs';

export const logout = (email?: string) => {
  if (email) {
    window.location.href = '/logout?email=' + email;
  } else {
    window.location.href = '/logout';
  }
};

export const handleCascaderOptions = (arr: any) => {
  return arr.map(function (item: any) {
    const newItem = {
      value: item.id,
      label: item.name,
      children: handleCascaderOptions(item.subList),
    };
    return newItem;
  });
};

export const formatDate = (
  timestamp: number,
  format = 'DD MMM YYYY, HH:mm'
) => {
  return dayjs(timestamp).format(format);
};
