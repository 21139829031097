import { AxiosRequestConfig } from 'axios';
import { decamelizeKeys } from 'humps';
import Cookies from 'js-cookie';
import { config } from 'process';
import store from '@reducers/index';

// 将请求的参数和数据都转成下划线形式
const camelToSnake = async (config: AxiosRequestConfig) => {
  const params = decamelizeKeys(config.params);
  const data = decamelizeKeys(config.data);

  config.params = params;
  config.data = data;
  return config;
};

const xReqUserHandler = async (config: AxiosRequestConfig) => {
  const email = store.getState().userInfo.fields.email;
  if (store.getState().userInfo.fields.email) {
    config.headers = {
      ...config.headers,
      'X-Req-User': email,
    };
  }
  return config;
};

const csrfHandler = async (config: AxiosRequestConfig) => {
  const csrfToken = Cookies.get('csrftoken');
  if (csrfToken) {
    config.headers = {
      ...config.headers,
      'X-CSRFToken': csrfToken,
    };
  }
  return config;
};

export const handleReqError = (error: any) => {
  console.error('error req', error);
  // toast({
  //   message: error ?  error.code + ': ' + error.message: 'request error'
  // })
};

// 通用 interceptor 可以加到这里
export const requestInterceptors = {
  camelToSnake,
  csrfHandler,
  xReqUserHandler,
};
