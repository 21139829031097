import React, { useEffect, useRef, useState } from 'react';
import styles from './index.module.scss';
import moreIcon from '@assets/more.png';
import { ConfigState } from '@reducers/config/type';
import { useSelector } from 'react-redux';
import { UserInfoState } from '@reducers/userInfo/type';

interface NavbarProps {
  menuList?: any[];
}

const Navbar = ({ menuList = [] }: NavbarProps) => {
  const [navListVisible, setNavListVisible] = useState(false);
  const userInfo = useSelector(
    (store: { userInfo: UserInfoState }) => store.userInfo
  );
  const config = useSelector((store: { config: ConfigState }) => store.config);

  const navListRef = useRef(null);

  const goToOtherPages = (item: any) => {
    if (item.name == 'profile') {
      window.location.href = config.hrisDomain + '/#/detail/' + userInfo.pk;
    } else {
      window.location.href = item.url;
    }
  };

  useEffect(() => {
    document.addEventListener('click', function (e) {
      if (
        navListRef.current &&
        !(navListRef.current as any).contains(e.target)
      ) {
        setNavListVisible(false);
      }
    });
  }, []);

  return (
    <div className={styles.navbar}>
      <div
        onClick={event => {
          event.stopPropagation();
          setNavListVisible(true);
        }}
        className={styles.home_info}>
        <img
          className={styles.home_icon}
          src={moreIcon}
        />
        <div className={styles.home_title}>Home</div>
      </div>
      <ul
        ref={navListRef}
        className={
          navListVisible
            ? `${styles.expand} ${styles.nav_list}`
            : styles.nav_list
        }>
        <li className={styles.nav_item}>
          <a
            href={`${config.hrisDomain}/#/`}
            className={styles.nav_link}>
            <span
              className={`sprite sprite-more-active ${styles.more_icon} ${styles.icon}`}></span>
            <span className={styles.menu_display}>Home</span>
          </a>
        </li>
        {menuList.map((menu, index) => {
          return (
            <li
              key={index}
              className={styles.nav_item}>
              <span
                key={index}
                onClick={() => goToOtherPages(menu)}
                className={styles.nav_link}>
                <span
                  className={`sprite ${menu.iconRight} ${styles.icon}`}></span>
                <span className={styles.menu_display}>{menu.display}</span>
              </span>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Navbar;
