import React, { useRef, useState } from 'react';
import { Search, Spin } from 'shopee-ui-react';
import { getWhiteListUser } from '@api/hrFunction';
import styles from './index.module.scss';
import { escapeRegExp } from 'lodash-es';

interface UserSearchProps {
  user: string;
  useFuzzy?: boolean;
  disabled?: boolean;
  error?: boolean;
  onChange: (value: string) => void;
}

export default ({
  user,
  useFuzzy = false,
  disabled = false,
  error = false,
  onChange,
}: UserSearchProps) => {
  const [loading, setLoading] = useState(false);
  const [staffList, setStaffList] = useState<
    {
      staffId: number;
      staffName: string;
      staffEmail: string;
    }[]
  >([]);

  const handleChange = async (value: any) => {
    onChange(value);
  };

  const handleSearchChange = async (value: any) => {
    if (!value) {
      setStaffList([]);
      return;
    }
    setLoading(true);
    const { data } = await getWhiteListUser({ keyword: value.trim() });
    setLoading(false);
    setStaffList(data.list);
  };

  const getHightLightTxt = (text: string, key: string) => {
    const reg = new RegExp('(' + escapeRegExp(key.trim()) + ')', 'ig');
    return text.replace(reg, `<span style="color: #2673dd">$1</span>`);
  };

  if (!useFuzzy) {
    return (
      <Search
        className={styles.search}
        error={error}
        placeholder="Input HR User’s Name/Email"
        disabled={disabled}
        value={user}
        clearable={!disabled}
        onChange={handleChange}
      />
    );
  }

  return (
    <Search
      className={styles.search}
      loading={loading}
      error={error}
      placeholder="Input HR User’s Name/Email"
      value={user}
      disabled={disabled}
      clearable={!disabled}
      onChange={handleChange}
      onSearch={handleSearchChange}
      fuzzy
      delay={400}>
      {!staffList.length ? (
        <div style={{ padding: '32px', textAlign: 'center' }}>No Result</div>
      ) : null}
      {staffList.map((item, index) => (
        <Search.Option
          selected={item.staffEmail === user}
          key={index}
          value={item.staffEmail}
          label={`${item.staffName}(${item.staffEmail})`}>
          <div
            style={{ marginBottom: 4 }}
            dangerouslySetInnerHTML={{
              __html: getHightLightTxt(item.staffName, user),
            }}></div>
          <div
            dangerouslySetInnerHTML={{
              __html: getHightLightTxt(item.staffEmail, user),
            }}
          />
        </Search.Option>
      ))}
    </Search>
  );
};
