import { DayInfo } from '@api/type';
import React from 'react';
import styles from './index.module.scss';
import { UserInfoState } from '@reducers/userInfo/type';
import { useSelector } from 'react-redux';

interface PopoverContentProps extends DayInfo {
  id: number;
  exempt: boolean;
}

const PopoverContent = (day: PopoverContentProps) => {
  const userInfo = useSelector(
    (store: { userInfo: UserInfoState }) => store.userInfo
  );

  return (
    <div className={styles.bar_info}>
      {!day.today ? (
        <p>
          {day.date}&nbsp;&nbsp;{day.hours}
          {day.hours !== '' ? 'hrs' : ''}
        </p>
      ) : null}
      {day.in && day.out ? (
        <p>
          {day.in} - {day.out}
        </p>
      ) : null}
      {day.holidayRemark ? <p>{day.holidayRemark}</p> : null}
      {day.leaveRemark ? (
        <p dangerouslySetInnerHTML={{ __html: day.leaveRemark }}></p>
      ) : null}
      {day.bizTravelRemark ? <p>{day.bizTravelRemark}</p> : null}
      {!day.isWeekend &&
      !day.holidayRemark &&
      !day.leaveRemark &&
      !day.bizTravelRemark &&
      day.in === '' &&
      day.out === '' ? (
        <p>
          {!day.exempt && !day.today ? 'Missing' : null}
          {day.exempt && !day.today ? 'Exempted.' : null}
        </p>
      ) : null}
      {(day.in === '' && day.out !== '') ||
      (day.in !== '' && day.out === '') ? (
        <p>{day.exempt ? 'Exempted.' : 'Incomplete'}</p>
      ) : null}
      {day.today ? (
        <p>{`Today's data will be ready at 8am tomorrow. But you may add remarks now.`}</p>
      ) : null}
      {day.memo || userInfo.pk === day.id ? (
        <p className={styles.memo}>
          {!day.memo && userInfo.pk === day.id ? (
            <span style={{ fontStyle: 'italic' }}>Click to remark</span>
          ) : null}
          {day.memo ? day.memo : null}
        </p>
      ) : null}
    </div>
  );
};

export default PopoverContent;
