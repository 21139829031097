import { SetUserInfo, UserAction, UserInfoState } from './type';

const initialState: UserInfoState = {
  pk: undefined,
  fields: {
    photo: '',
    fullName: '',
    canAccessProfile: false,
    canExempt: false,
    vnLogo: false,
    roleName: [],
    email: '',
  },
};

export function userInfoReducer(state = initialState, action: UserAction) {
  switch (action.type) {
    case SetUserInfo:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
