import React, { useEffect, useMemo, useRef, useState } from 'react';
import styles from './Detail.module.scss';
import { Button, Modal, Spin, Toast } from 'shopee-ui-react';
import { WhiteHrDetail } from '@api/hrFunction/type';
import {
  deleteWhiteUser,
  editWhiteUser,
  getWhiteHrDetail,
  getWhitelistTeamScope,
} from '@api/hrFunction';
import { useNavigate, useParams } from 'react-router-dom';
import EditModal from './components/EditModal';
import { formatDate, handleCascaderOptions } from '@util/index';
import { selectAllValue } from '@contants/index';
import { CascaderOptionItem } from '@components/Cascader';

interface modalRefProps {
  open: ({ teamIds, user }: { teamIds: any[]; user: string }) => void;
  close: () => void;
  getValue: () => { user: string; teamIds: number[] };
}

export default () => {
  const modalRef = useRef<modalRefProps>(null);

  const navigate = useNavigate();
  const [hrInfo, setHrInfo] = useState<WhiteHrDetail | undefined>();
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const [teamOptions, setTeamOptions] = useState([]);

  const getHrInfoDetail = async () => {
    setLoading(true);
    const { code, data, message } = await getWhiteHrDetail({
      hrUserId: Number(id),
    });
    if (code !== 0) {
      Toast.error(message);
      setLoading(false);
      return;
    }
    setLoading(false);
    setHrInfo(data);
  };

  const getTeamInfo = async () => {
    const { code, data } = await getWhitelistTeamScope();
    if (code !== 0) {
      Toast.error('Get Select Info Error');
      return;
    }
    setTeamOptions(handleCascaderOptions(data));
  };

  const handleSelectTeamIds = (teamIds: number[], teamOptions: any[]) => {
    const selectedValues: any[] = [];

    const deepSelectOption = (item: CascaderOptionItem) => {
      selectedValues.push(item.value);
      if (item.children && item.children.length) {
        item.children.forEach(child => {
          deepSelectOption(child);
        });
      }
    };

    const deepOption = (
      item: CascaderOptionItem,
      selectCurrent = false
    ) => {
      if (teamIds.includes(item.value as number) || selectCurrent) {
        deepSelectOption(item);
      } else if (item.children && item.children.length) {
        item.children.forEach(child => {
          deepOption(child);
        });
      }
    };

    if (teamIds.includes(selectAllValue)) {
      teamOptions.forEach(option => {
        deepOption(option, true);
      });
    } else {
      teamOptions.forEach(option => {
        deepOption(option);
      });
    }

    return selectedValues;
  };

  const handleClickEdit = () => {
    modalRef.current?.open({
      user: `${hrInfo?.hrName} (${hrInfo?.hrEmail})` || '',
      teamIds: handleSelectTeamIds(
        hrInfo?.teamScopeList.map(item => item.teamId) || [],
        teamOptions
      ),
    });
  };

  const handleModalConfirm = async () => {
    const value = modalRef.current?.getValue();
    const { code, message } = await editWhiteUser({
      hrUserId: Number(id),
      teamIds: value?.teamIds || [],
    });
    if (code === 9001) {
      Toast.error('Failed to save as HR user is inactive');
    } else if (code !== 0) {
      Toast.error(message);
    } else {
      Toast.success('HR user edited successfully');
      getHrInfoDetail();
      modalRef.current?.close();
    }
  };

  const handleClickDelete = (id: number) => {
    const modal: any = Modal.confirm({
      title: 'Delete HR User',
      content:
        'Deleting this HR user would remove all HR data access maintained for this user within the Attendance system. Confirm?',
      onConfirm: () => {
        return new Promise(resolve => {
          modal.update({ isConfirmLoading: true });
          deleteWhiteUser({ hrUserId: id }).then(({ code, message }) => {
            if (code !== 0) {
              Toast.error(message);
            } else {
              Toast.success('HR user deleted successfully');
              navigate('/hr-function/list');
            }
            resolve();
          });
        });
      },
    });
  };

  useEffect(() => {
    getTeamInfo();
    getHrInfoDetail();
  }, []);

  if (loading) {
    return (
      <div className={styles.loading_wrapper}>
        <Spin />
      </div>
    );
  }

  return (
    <div className={styles.detail_wrapper}>
      <div className={styles.back_wrapper}>
        <Button
          onClick={() => navigate('/hr-function/list')}
          type="link"
          size="large"
          icon="arrow-left">
          Back
        </Button>
      </div>
      <div className={styles.detail_content}>
        <div className={styles.header_wrapper}>
          <div className={styles.title}>HR User&#39;s Access Details</div>
          <div className={styles.actions}>
            <Button
              onClick={() => handleClickDelete(hrInfo?.id as number)}
              className={styles.delete}>
              Delete
            </Button>
            <Button
              onClick={() => handleClickEdit()}
              type="primary">
              Edit
            </Button>
          </div>
        </div>
        <div className={styles.tip}>
          The configuration on this page determines the HR user&#39;s data scope
          for <i>Attendance Report</i> and <i>Search Staff</i> within the
          Attendance Web.
        </div>
        <div className={styles.info_wrapper}>
          <div className={styles.info_item}>
            <div className={styles.detail_name}>HR User</div>
            <div className={styles.detail_text}>
              {`${hrInfo?.hrName} (${hrInfo?.hrEmail})`}
            </div>
          </div>

          <div className={styles.info_item}>
            <div className={styles.detail_name}>Team Scope</div>
            <div className={styles.detail_text}>
              {hrInfo?.teamScopeList && hrInfo?.teamScopeList.length
                ? hrInfo?.teamScopeList.map((item, index) => {
                    return (
                      <p
                        className={styles.team_item}
                        key={index}>
                        {item.teamPath}
                      </p>
                    );
                  })
                : '-'}
            </div>
          </div>

          <div className={styles.info_item}>
            <div className={styles.detail_name}>Updated By</div>
            <div className={styles.detail_text}>
              {`${hrInfo?.updatedByName}${
                hrInfo?.updatedByEmail ? ` (${hrInfo?.updatedByEmail})` : ''
              }`}
            </div>
          </div>

          <div className={styles.info_item}>
            <div className={styles.detail_name}>Updated On</div>
            <div className={styles.detail_text}>
              {formatDate(Number(hrInfo?.updatedOn) * 1000)}
            </div>
          </div>
        </div>

        <EditModal
          ref={modalRef}
          type="edit"
          teamOptions={teamOptions}
          onConfirm={handleModalConfirm}
        />
      </div>
    </div>
  );
};
