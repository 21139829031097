import {
  addExemption,
  addMemo,
  getAttendanceGroupFeeds,
  getAttendanceMemoConfig,
} from '@api/index';
import { AttendanceGroupFeedResponse } from '@api/type';
import UserCalendar from '@components/UserCalender';
import { UserInfoState } from '@reducers/userInfo/type';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Toast } from 'shopee-ui-react';
import styles from './index.module.scss';
import { CalendarRangeMap } from '@contants/index';
import UserCalendarFilter from '@components/UserCalender/UserCalendarFilter';
import dayjs from 'dayjs';

export interface MyTeamProps {
  feedId?: number;
  defaultRange?: number;
  hasExportPermission?: boolean;
  hasFilterPermission?: boolean;
}

const MyTeam = (props: MyTeamProps) => {
  const [data, setData] = useState<AttendanceGroupFeedResponse>({});
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [options, setOptions] = useState<any>({
    id: props.feedId,
    from: 0,
    days: CalendarRangeMap[props.defaultRange || 0],
    start: undefined,
    end: undefined,
  });

  const handleAddExemption = async (staff: any) => {
    let op = 1;
    if (staff.exempt) {
      op = 0;
    }
    const { ret, exemptBy, exemptDate } = await addExemption({
      id: staff.id,
      op,
    });
    if (ret === 0) {
      const info = data.attendance?.filter(item => item.id === staff.id)[0];
      if (info) {
        info.exempt = !staff.exempt;
        info.exemptBy = exemptBy;
        info.exemptDate = exemptDate;
      }
      setData({
        ...data,
      });
    }
  };

  const handleStartChange = (value?: Date) => {
    if (value) {
      setOptions({
        ...options,
        start: dayjs(value).format('DD/MM/YYYY'),
      });
    } else {
      setOptions({
        ...options,
        start: undefined,
      });
    }
  };

  const handleEndDateChange = (value?: Date) => {
    if (value) {
      setOptions({
        ...options,
        end: dayjs(value).format('DD/MM/YYYY'),
      });
    } else {
      setOptions({
        ...options,
        end: undefined,
      });
    }
  };

  const handleRangeChange = (range: string) => {
    const listOptions = {
      ...options,
      start: undefined,
      end: undefined,
      from: 0,
      days: range,
    };
    getAttendanceList(listOptions);
    setOptions({
      ...options,
      days: range,
    });
    setLoadingMore(false);
  };

  const handleSubmit = () => {
    const listOptions = {
      ...options,
      from: 0,
    };
    if (!listOptions.end && listOptions.start) {
      listOptions.end = dayjs().format('DD/MM/YYYY');
      listOptions.days = undefined;
      setOptions(listOptions);
    } else if (listOptions.end && !listOptions.start) {
      Toast.error('Please Select a Start Date');
      return;
    }
    setLoadingMore(false);
    getAttendanceList(listOptions);
  };

  const handleExport = () => {
    const listOptions = {
      ...options,
    };
    if (!listOptions.start) {
      Toast.error('Please Select a Start Date');
      return;
    } else if (!listOptions.end) {
      listOptions.end = dayjs().format('DD/MM/YYYY');
    }
    const downloadUrl = `/api/attendance/export/?mode=my_team&from_date=${listOptions.start}&to_date=${listOptions.end}`;
    window.open(downloadUrl, '_blank');
  };

  const handleLoadMore = async () => {
    if (data.hasMore) {
      setLoadingMore(true);
      const listOptions = {
        ...options,
        from: data.index,
      };
      setOptions(listOptions);
      await getAttendanceList(listOptions, true);
      setLoadingMore(false);
    }
  };

  const getAttendanceList = async (
    listOptions: any,
    isLoadMore = false
  ) => {
    try {
      setLoading(true);
      const res = await getAttendanceGroupFeeds(listOptions);
      if (!isLoadMore) {
        setData(res);
      } else {
        res.attendance = (data.attendance || []).concat(res.attendance || []);
        setData(res);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAttendanceList(options);
  }, []);

  return (
    <div className={styles.my_team}>
      <div className={styles.title_wrapper}>
        {data.isDirect ? (
          <div
            className={
              styles.tip
            }>{`You have >100 subordinates in total. Only direct reports are shown.`}</div>
        ) : null}
        My Team
      </div>
      <div className={styles.date_wrapper}>
        <UserCalendarFilter
          hasExportPermission={props.hasExportPermission}
          hasFilterPermission={props.hasFilterPermission}
          currentRange={options.days}
          startDate={options.start}
          endDate={options.end}
          startDateChange={handleStartChange}
          endDateChange={handleEndDateChange}
          rangeChange={handleRangeChange}
          onSubmit={handleSubmit}
          onExport={handleExport}
        />
      </div>
      <UserCalendar
        {...data}
        loading={loading}
        loadingMore={loadingMore}
        addExemption={handleAddExemption}
        loadMore={handleLoadMore}
      />
    </div>
  );
};

export default MyTeam;
