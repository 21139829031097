export interface UserInfoState {
  pk?: number; // staffId
  fields: {
    roleName: string[];
    canAccessProfile: boolean;
    canExempt: boolean;
    photo: string;
    vnLogo: boolean;
    fullName: string;
    email: string;
  };
}

export const SetUserInfo = 'SetUserInfo';

interface SetUserInfoAction {
  type: typeof SetUserInfo;
  payload: UserInfoState;
}

export type UserAction = SetUserInfoAction;
