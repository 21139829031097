import React from 'react';
import styles from './index.module.scss';
import { Avatar, Button } from 'shopee-ui-react';
import { useSelector } from 'react-redux';
import { UserInfoState } from '@reducers/userInfo/type';
import { ConfigState } from '@reducers/config/type';
import { logout } from '@util/index';

interface HeaderProps {
  username: string;
  photoUrl: string;
  hrisUrl: string;
  showVnLogo?: boolean;
}

const Header = ({ username, photoUrl, hrisUrl, showVnLogo }: HeaderProps) => {
  const userInfo = useSelector(
    (store: { userInfo: UserInfoState }) => store.userInfo
  );
  const config = useSelector((store: { config: ConfigState }) => store.config);

  const goToProfile = () => {
    if (userInfo.fields.canAccessProfile) {
      window.location.href = config.hrisDomain + '/#/detail/' + userInfo.pk;
    }
  };

  const handleLogout = () => {
    logout();
  };

  return (
    <header className={styles.header}>
      <div className={styles.logo_info}>
        {showVnLogo ? (
          <a
            href="/#/"
            className="sprite sprite-logo_vn"></a>
        ) : (
          <a
            className={styles.sea_logo}
            href={`${hrisUrl}/#/`}></a>
        )}
      </div>
      <div className={styles.login_info}>
        <div
          onClick={goToProfile}
          className={styles.photo_name_wrapper}>
          <Avatar src={photoUrl}></Avatar>
          {username ? (
            <span className={styles.username}>{username}</span>
          ) : null}
        </div>
        <Button
          onClick={handleLogout}
          className={styles.logout}>
          Logout
        </Button>
      </div>
    </header>
  );
};

export default Header;
