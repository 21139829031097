/* eslint-disable */
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { Dropdown, Input, Search, Tooltip } from 'shopee-ui-react';
import styles from './index.module.scss';
import { FixedSizeList as List } from 'react-window';
import { selectAllValue } from '@contants/index';
import { escapeRegExp } from 'lodash-es';

export interface CascaderOptionItem {
  value: string | number;
  label: string;
  children: CascaderOptionItem[];
}

interface CascaderProps {
  loading?: boolean;
  options: CascaderOptionItem[];
  selectedValueArr: string[];
  error?: boolean;
  setSelectValueArr: (arr: string[]) => void;
}

const Cascader = (
  {
    loading = false,
    options = [],
    selectedValueArr = [],
    error = false,
    setSelectValueArr,
  }: CascaderProps,
  ref: any
) => {
  const [visible, setVisible] = useState(false);
  const [searchKey, setSearchKey] = useState('');
  const [searchInfoArr, setSearchInfoArrs] = useState<any>([]);
  const [boxData, setBoxData] = useState<any>([]);
  const [currentHightLightItem, setCurrentHightLightItem] = useState<any>(null);
  // const [innerSelectValueArr, setInnerSelectValueArr] = useState(selectValueArr)

  useImperativeHandle(
    ref,
    () => {
      return {
        getRealSelectedValues: () => {
          if (computedAllItemIsSelected(boxData[0], selectedValueArr))
            return [-1];
          const realSelectArr: any[] = [];

          const deepRealSelectArrOption = (item: any, realSelectArr: any[]) => {
            if (item.children && item.children.length) {
              item.children.forEach((child: any) => {
                if (!selectedValueArr.includes(child.value)) {
                  deepRealSelectArrOption(child, realSelectArr);
                } else {
                  realSelectArr.push(child.value);
                }
              });
            }
          };

          options.forEach((item: any) => {
            if (!selectedValueArr.includes(item.value)) {
              deepRealSelectArrOption(item, realSelectArr);
            } else {
              realSelectArr.push(item.value);
            }
          });

          return realSelectArr;
        },
      };
    },
    [selectedValueArr, boxData, options]
  );

  const handleVisibleChange = (visible: boolean) => {
    if (!visible) {
      setBoxData(initBoxData(options));
      setSearchKey('');
      setCurrentHightLightItem(null);
    }
    setVisible(visible);
  };

  const computedCurrentBoxSelectCount = (
    currentBoxData: any,
    selectedValueArr: string[]
  ) => {
    let count = 0;
    currentBoxData.forEach((item: any) => {
      if (selectedValueArr.includes(item.value)) count++;
    });
    return count;
  };

  const computedAllItemIsIndicator = (
    currentBoxData: any,
    selectedValueArr: string[]
  ) => {
    var count = computedCurrentBoxSelectCount(currentBoxData, selectedValueArr);
    return count < currentBoxData.length && count;
  };

  const computedAllItemIsSelected = (
    currentBoxData: any,
    selectedValueArr: string[]
  ) => {
    return Boolean(
      computedCurrentBoxSelectCount(currentBoxData, selectedValueArr) >=
        currentBoxData.length && currentBoxData.length
    );
  };

  const optionsMap = useMemo(() => {
    const map: any = {};
    const deepChildren = (children: any, childrenValues: any): any => {
      if (children && children.length) {
        children.forEach(function (child: any) {
          childrenValues.push(child.value);
          deepChildren(child, childrenValues);
        });
      }
      return childrenValues;
    };
    const deepSelectOptions = (
      item: CascaderOptionItem,
      parentValues: any,
      showLabel: string
    ) => {
      map[item.value] = {
        ...item,
        childrenValues: deepChildren(item.children, []),
        parentValues,
        showLabel,
      };
      if (item.children && item.children.length) {
        item.children.forEach(function (child: any) {
          deepSelectOptions(
            child,
            [...parentValues, item.value],
            `${showLabel} > ${child.label}`
          );
        });
      }
    };
    options.forEach(function (item) {
      deepSelectOptions(item, [], item.label);
    });

    return map;
  }, [options]);

  const selectInfoArr = useMemo(() => {
    if (boxData[0] && computedAllItemIsSelected(boxData[0], selectedValueArr))
      return [
        {
          label: 'All',
          showLabel: 'All',
          value: selectAllValue,
        },
      ];
    const start = +new Date();
    const infoArr: any[] = [];
    selectedValueArr.forEach(value => {
      const option = optionsMap[value];
      let flag = false;
      for (let i = 0; i < option.parentValues.length; i++) {
        if (selectedValueArr.includes(option.parentValues[i])) {
          flag = true;
          break;
        }
      }
      if (!flag) infoArr.push(option);
    });
    return infoArr.slice(0, 5);
  }, [boxData, selectedValueArr]);

  const clearSelect = function ($event: any) {
    if ($event) {
      $event.stopPropagation();
    }
    setSelectValueArr([]);
  };

  const selectAllClassName = (
    currentBoxData: any,
    selectedValueArr: string[]
  ) => {
    const isSelect =
      computedAllItemIsIndicator(currentBoxData, selectedValueArr) ||
      computedAllItemIsSelected(currentBoxData, selectedValueArr)
        ? styles['is-select']
        : '';
    return `${styles['select-item']} ${isSelect}`;
  };

  const computedChildSelectCount = function (
    item: any,
    count: number,
    selectedValueArr: string[]
  ) {
    if (item && item.children && item.children.length) {
      item.children.forEach(function (child: any) {
        if (selectedValueArr.includes(child.value)) count++;
        if (child.children && child.children.length)
          count = computedChildSelectCount(child, count, selectedValueArr);
      });
    }
    return count;
  };

  const computedIsIndicator = (item: any, selectedValueArr: string[]) => {
    return (
      !selectedValueArr.includes(item.value) &&
      computedChildSelectCount(item, 0, selectedValueArr)
    );
  };

  const computedIsHighLight = (item: any, currentHightLightItem: any) => {
    if (!currentHightLightItem) return false;
    if (item.value === currentHightLightItem.value) return true;
    if (item && item.children && item.children.length) {
      for (var i = 0; i < item.children.length; i++) {
        if (computedIsHighLight(item.children[i], currentHightLightItem)) {
          return true;
        }
      }
    }
    return false;
  };

  const selectItemClassName = (
    item: any,
    selectedValueArr: string[],
    currentHightLightItem: any
  ) => {
    const isSelect =
      selectedValueArr.includes(item.value) ||
      computedIsIndicator(item, selectedValueArr)
        ? ` ${styles['is-select']}`
        : '';
    const currentHighLight = computedIsHighLight(item, currentHightLightItem)
      ? ` ${styles['text-high-light']}`
      : '';
    return `${styles['select-item']}${isSelect}${currentHighLight}`;
  };

  const computedHasChildren = (item: any) => {
    return item && item.children && item.children.length;
  };

  const getParent = (parent: any, child: any, item: any) => {
    if (child.value === item.value) {
      return parent;
    }
    if (child.children && child.children.length) {
      for (var i = 0; i < child.children.length; i++) {
        var parent = getParent(child, child.children[i], item);
        if (parent) {
          return parent;
        }
      }
    }
    return null;
  };

  const computedMutipleSelectAll = (
    searchOptions: any[],
    selectedValueArr: string[]
  ) => {
    let count = 0;
    searchOptions.forEach((item: any) => {
      if (selectedValueArr.includes(item.value)) count++;
    });
    return count >= searchOptions.length;
  };

  const handleSelectMutipleAll = (
    searchOptions: any[],
    selectedValueArr: string[]
  ) => {
    const _selectedValueArr = selectedValueArr.slice();
    if (computedMutipleSelectAll(searchOptions, _selectedValueArr)) {
      searchOptions.forEach((item: any) => {
        handleDelete(item, _selectedValueArr);
      });
    } else {
      searchOptions.forEach((item: any) => {
        selectItem(item, _selectedValueArr);
      });
    }
    setSelectValueArr(_selectedValueArr);
  };

  const removeItem = (item: any, _selectedValueArr: string[]) => {
    if (_selectedValueArr.includes(item.value)) {
      var valueIndex = _selectedValueArr.indexOf(item.value);
      if (valueIndex > -1) {
        _selectedValueArr.splice(valueIndex, 1);
      }
    }
  };

  const handleDelete = (
    item: any,
    _selectedValueArr: string[],
    isParent = false,
    $event?: any
  ) => {
    if (item.value === selectAllValue) {
      handleAllItemSelect(boxData[0], _selectedValueArr);
      return;
    }
    if (_selectedValueArr.includes(item.value)) {
      const parent =
        optionsMap[item.value].parentValues.length > 0
          ? optionsMap[
              optionsMap[item.value].parentValues[
                optionsMap[item.value].parentValues.length - 1
              ]
            ]
          : null;

      if (parent) {
        handleDelete(parent, _selectedValueArr, true);
      }

      removeItem(item, _selectedValueArr);

      if (!isParent && item.children && item.children.length) {
        item.children.forEach((child: any) => {
          handleDelete(child, _selectedValueArr);
        });
      }
    }

    if ($event) {
      $event.stopPropagation();
      setSelectValueArr(_selectedValueArr);
    }
  };

  const selectItem = (item: any, _selectedValueArr: string[]) => {
    if (!_selectedValueArr.includes(item.value)) {
      _selectedValueArr.push(item.value);

      const parent =
        optionsMap[item.value].parentValues.length > 0
          ? optionsMap[
              optionsMap[item.value].parentValues[
                optionsMap[item.value].parentValues.length - 1
              ]
            ]
          : null;

      // const parent = optionsMap[item.value].parentValues[0] ?  optionsMap[optionsMap[item.value].parentValues[0]] : null

      if (
        parent &&
        parent.children &&
        !_selectedValueArr.includes(parent.value)
      ) {
        var count = 0;

        _selectedValueArr.forEach(value => {
          if (parent.childrenValues.includes(value)) count++;
        });

        if (count >= parent.childrenValues.length) {
          selectItem(parent, _selectedValueArr);
        }
      }

      if (item.children && item.children.length) {
        item.children.forEach(function (child: any) {
          selectItem(child, _selectedValueArr);
        });
      }
    }
  };

  const handleSelect = (item: any, selectedValueArr: string[]) => {
    const _selectedValueArr = selectedValueArr.slice();
    if (!selectedValueArr.includes(item.value)) {
      // console.log('select')
      selectItem(item, _selectedValueArr);
    } else {
      handleDelete(item, _selectedValueArr);
    }
    setSelectValueArr(_selectedValueArr);
  };

  const handleAllItemSelect = (
    currentBoxData: any,
    selectedValueArr: string[]
  ) => {
    const _selectedValueArr = selectedValueArr.slice();
    if (computedAllItemIsSelected(currentBoxData, selectedValueArr)) {
      currentBoxData.forEach((item: any) => {
        handleDelete(item, _selectedValueArr);
      });
    } else {
      currentBoxData.forEach((item: any) => {
        selectItem(item, _selectedValueArr);
      });
    }
    setSelectValueArr(_selectedValueArr);
  };

  const getSearchHighLight = function (label: string, searchKey: string) {
    const reg = new RegExp('(' + escapeRegExp(searchKey.trim()) + ')', 'ig');
    return label.replace(
      reg,
      `<span class="${styles['high-light-text']}">$1</span>`
    );
  };

  const initBoxData = (options: CascaderOptionItem[]) => {
    return [
      options.map(function (item) {
        return {
          ...item,
          deep: 0,
        };
      }),
    ];
  };

  const handleClick = (item: any) => {
    var currentDeep = boxData.length - 1;
    if (item.deep === currentDeep && item.children && item.children.length) {
      boxData.push(
        item.children.map(function (child: any) {
          return {
            ...child,
            deep: item.deep + 1,
          };
        })
      );
      setBoxData(boxData);
      setCurrentHightLightItem(item);
    } else if (
      item.deep < currentDeep &&
      item.children &&
      item.children.length
    ) {
      const _boxData = boxData.slice(0, item.deep + 1);
      // console.log('-boxData', _boxData)
      _boxData.push(
        item.children.map(function (child: any) {
          return {
            ...child,
            deep: item.deep + 1,
          };
        })
      );
      setBoxData(_boxData.slice());
      setCurrentHightLightItem(item);
    }
  };

  const deepSearchOptions = (
    item: any,
    currentText: string,
    searchInfoArr: any
  ) => {
    searchInfoArr.push({
      ...optionsMap[item.value],
      label: currentText,
      value: item.value,
      children: item.children,
    });
    if (item.children && item.children.length) {
      item.children.forEach(function (child: any) {
        deepSearchOptions(
          child,
          `${currentText} > ${child.label}`,
          searchInfoArr
        );
      });
    }
  };

  var getSerachInfoArr = function (searchKey = '', searchOptions: any) {
    return searchOptions.filter((item: any) =>
      item.label.toLocaleLowerCase().includes(searchKey.toLocaleLowerCase())
    );
  };

  const handleSearchChange = (value: any, searchOptions: any) => {
    setSearchKey(value);
    setSearchInfoArrs(getSerachInfoArr(value.trim(), searchOptions));
  };

  const searchOptions = useMemo(() => {
    const searchInfoArr: any = [];
    options.forEach(function (item: any) {
      deepSearchOptions(item, item.label, searchInfoArr);
    });
    return searchInfoArr;
  }, [options]);

  useEffect(() => {
    setBoxData(initBoxData(options));
  }, [options]);

  return (
    <div className={styles['shopee-cascader']}>
      <Dropdown
        visible={visible}
        showTimeout={0}
        hideTimeout={0}
        onVisibleChange={handleVisibleChange}
        overlay={
          <Dropdown.DropdownMenu className={styles['shopee-cascader']}>
            <div
              className={`${styles['dropdown-menu']} ${styles['dropdown-wrapper']}`}
              onClick={$event => $event.stopPropagation()}>
              <div className={styles['search-wrapper']}>
                <div className={styles['search-input-wrapper']}>
                  <Input
                    clearable
                    placeholder="Select"
                    value={searchKey}
                    onChange={(key: any) =>
                      handleSearchChange(key, searchOptions)
                    }
                    prefixIcon="search"
                  />
                </div>
              </div>
              {!loading && !searchKey.trim() ? (
                <div className={styles['options-wrapper']}>
                  {boxData.map((data: any, index: any) => {
                    return (
                      <List
                        key={index}
                        height={190}
                        itemCount={data.length + 1}
                        itemSize={35}
                        style={{ flexShrink: 0 }}
                        width={155}>
                        {({ index, style }: any) => {
                          const item = data[index - 1];
                          if (index === 0) {
                            return (
                              <div
                                className={selectAllClassName(
                                  data,
                                  selectedValueArr
                                )}>
                                <span
                                  onClick={() =>
                                    handleAllItemSelect(data, selectedValueArr)
                                  }
                                  className={styles['checkbox']}>
                                  {computedAllItemIsIndicator(
                                    data,
                                    selectedValueArr
                                  ) ? (
                                    <i className={styles['icon']}>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 16 16">
                                        <path
                                          fillRule="evenodd"
                                          d="M3.75,7 L12.25,7 C12.6642136,7 13,7.33578644 13,7.75 C13,8.16421356 12.6642136,8.5 12.25,8.5 L3.75,8.5 C3.33578644,8.5 3,8.16421356 3,7.75 C3,7.33578644 3.33578644,7 3.75,7 Z"></path>
                                      </svg>
                                    </i>
                                  ) : null}
                                  {computedAllItemIsSelected(
                                    data,
                                    selectedValueArr
                                  ) ? (
                                    <i className={styles['icon']}>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 16 16">
                                        <path d="M4.03033009,7.46966991 C3.73743687,7.1767767 3.26256313,7.1767767 2.96966991,7.46966991 C2.6767767,7.76256313 2.6767767,8.23743687 2.96966991,8.53033009 L6.32804531,11.8887055 C6.62093853,12.1815987 7.09581226,12.1815987 7.38870548,11.8887055 L13.2506629,6.02674809 C13.5435561,5.73385487 13.5435561,5.25898114 13.2506629,4.96608792 C12.9577697,4.6731947 12.4828959,4.6731947 12.1900027,4.96608792 L6.8583754,10.2977152 L4.03033009,7.46966991 Z"></path>
                                      </svg>
                                    </i>
                                  ) : null}
                                </span>
                                <div className={styles['select-item-text']}>
                                  All
                                </div>
                              </div>
                            );
                          }
                          return (
                            <div
                              style={style}
                              key={index}
                              className={selectItemClassName(
                                item,
                                selectedValueArr,
                                currentHightLightItem
                              )}
                              onClick={() => handleClick(item)}>
                              <span
                                onClick={() =>
                                  handleSelect(item, selectedValueArr)
                                }
                                className={styles['checkbox']}>
                                {computedIsIndicator(item, selectedValueArr) ? (
                                  <i className={styles['icon']}>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 16 16">
                                      <path
                                        fillRule="evenodd"
                                        d="M3.75,7 L12.25,7 C12.6642136,7 13,7.33578644 13,7.75 C13,8.16421356 12.6642136,8.5 12.25,8.5 L3.75,8.5 C3.33578644,8.5 3,8.16421356 3,7.75 C3,7.33578644 3.33578644,7 3.75,7 Z"></path>
                                    </svg>
                                  </i>
                                ) : null}
                                {selectedValueArr.includes(item.value) ? (
                                  <i className={styles['icon']}>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 16 16">
                                      <path d="M4.03033009,7.46966991 C3.73743687,7.1767767 3.26256313,7.1767767 2.96966991,7.46966991 C2.6767767,7.76256313 2.6767767,8.23743687 2.96966991,8.53033009 L6.32804531,11.8887055 C6.62093853,12.1815987 7.09581226,12.1815987 7.38870548,11.8887055 L13.2506629,6.02674809 C13.5435561,5.73385487 13.5435561,5.25898114 13.2506629,4.96608792 C12.9577697,4.6731947 12.4828959,4.6731947 12.1900027,4.96608792 L6.8583754,10.2977152 L4.03033009,7.46966991 Z"></path>
                                    </svg>
                                  </i>
                                ) : null}
                              </span>
                              <Tooltip
                                zIndex={99999}
                                content={item.label}
                                placement="topLeft">
                                <div className={styles['select-item-text']}>
                                  {item.label}
                                </div>
                              </Tooltip>
                              {computedHasChildren(item) ? (
                                <span className={styles['more']}>
                                  <i
                                    className={`${styles['icon']} ${styles['has-more']}`}>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 16 16">
                                      <path d="M9.18933983,8 L5.21966991,11.9696699 C4.9267767,12.2625631 4.9267767,12.7374369 5.21966991,13.0303301 C5.51256313,13.3232233 5.98743687,13.3232233 6.28033009,13.0303301 L10.7803301,8.53033009 C11.0732233,8.23743687 11.0732233,7.76256313 10.7803301,7.46966991 L6.28033009,2.96966991 C5.98743687,2.6767767 5.51256313,2.6767767 5.21966991,2.96966991 C4.9267767,3.26256313 4.9267767,3.73743687 5.21966991,4.03033009 L9.18933983,8 Z"></path>
                                    </svg>
                                  </i>
                                </span>
                              ) : null}
                            </div>
                          );
                        }}
                      </List>
                    );
                  })}
                  {!boxData.length ? (
                    <div className={styles['no-data']}>No Data</div>
                  ) : null}
                </div>
              ) : null}
              {!loading && searchKey.trim() ? (
                <div className={styles['options-search-wrapper']}>
                  {searchInfoArr.length ? (
                    <List
                      height={190}
                      itemCount={searchInfoArr.length + 1}
                      itemSize={35}
                      width={320}>
                      {({ index, style }: any) => {
                        const item = searchInfoArr[index - 1];
                        if (index === 0) {
                          return (
                            <div
                              key={index}
                              style={style}
                              className={
                                computedMutipleSelectAll(
                                  searchInfoArr,
                                  selectedValueArr
                                )
                                  ? `${styles['select-item']} ${styles['is-select']}`
                                  : `${styles['select-item']}`
                              }
                              onClick={() =>
                                handleSelectMutipleAll(
                                  searchInfoArr,
                                  selectedValueArr
                                )
                              }>
                              <div className={styles['select-item-text']}>
                                Select All: Current Filter
                              </div>
                              {computedMutipleSelectAll(
                                searchInfoArr,
                                selectedValueArr
                              ) ? (
                                <span className={styles['check']}>
                                  <i
                                    className={`${styles['icon']} ${styles['check-icon']}`}>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 16 16">
                                      <path d="M4.03033009,7.46966991 C3.73743687,7.1767767 3.26256313,7.1767767 2.96966991,7.46966991 C2.6767767,7.76256313 2.6767767,8.23743687 2.96966991,8.53033009 L6.32804531,11.8887055 C6.62093853,12.1815987 7.09581226,12.1815987 7.38870548,11.8887055 L13.2506629,6.02674809 C13.5435561,5.73385487 13.5435561,5.25898114 13.2506629,4.96608792 C12.9577697,4.6731947 12.4828959,4.6731947 12.1900027,4.96608792 L6.8583754,10.2977152 L4.03033009,7.46966991 Z"></path>
                                    </svg>
                                  </i>
                                </span>
                              ) : null}
                            </div>
                          );
                        }
                        return (
                          <div
                            key={index}
                            style={style}
                            className={
                              selectedValueArr.includes(item.value)
                                ? `${styles['select-item']} ${styles['is-select']}`
                                : `${styles['select-item']}`
                            }
                            onClick={() =>
                              handleSelect(item, selectedValueArr)
                            }>
                            <Tooltip
                              zIndex={99999}
                              content={item.showLabel}
                              placement="topLeft">
                              <div
                                className={styles['select-item-text']}
                                dangerouslySetInnerHTML={{
                                  __html: getSearchHighLight(
                                    item.showLabel,
                                    searchKey
                                  ),
                                }}></div>
                            </Tooltip>
                            {selectedValueArr.includes(item.value) ? (
                              <span className={styles['check']}>
                                <i
                                  className={`${styles['icon']} ${styles['check-icon']}`}>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 16 16">
                                    <path d="M4.03033009,7.46966991 C3.73743687,7.1767767 3.26256313,7.1767767 2.96966991,7.46966991 C2.6767767,7.76256313 2.6767767,8.23743687 2.96966991,8.53033009 L6.32804531,11.8887055 C6.62093853,12.1815987 7.09581226,12.1815987 7.38870548,11.8887055 L13.2506629,6.02674809 C13.5435561,5.73385487 13.5435561,5.25898114 13.2506629,4.96608792 C12.9577697,4.6731947 12.4828959,4.6731947 12.1900027,4.96608792 L6.8583754,10.2977152 L4.03033009,7.46966991 Z"></path>
                                  </svg>
                                </i>
                              </span>
                            ) : null}
                          </div>
                        );
                      }}
                    </List>
                  ) : null}

                  {!options.length ? (
                    <div className={styles['no-data']}>No Data</div>
                  ) : null}
                  {options.length && !searchInfoArr.length ? (
                    <div className={styles['no-data']}>No Field Found</div>
                  ) : null}
                </div>
              ) : null}
              {loading ? (
                <div className={styles['select-loading']}>loading...</div>
              ) : null}
            </div>
          </Dropdown.DropdownMenu>
        }>
        <div
          className={
            error
              ? `${styles['select-wrapper']} ${styles.error}`
              : styles['select-wrapper']
          }>
          <div
            style={{ display: '-webkit-box' }}
            className={styles['select-mutiple']}>
            {selectInfoArr.map((info, index) => {
              return (
                <Tooltip
                  key={info.value}
                  content={info.showLabel}>
                  <div
                    key={index}
                    className={styles['select-value-wrapper']}>
                    <span className={styles['select-value']}>
                      {info.showLabel}
                    </span>
                    <span
                      className={styles['delete-icon-wrapper']}
                      onClick={$event =>
                        handleDelete(
                          info,
                          selectedValueArr.slice(),
                          false,
                          $event
                        )
                      }>
                      <i className={styles['delete-icon']}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 16 16">
                          <path
                            fillRule="evenodd"
                            d="M2.85355339,1.98959236 L8.157,7.29314575 L13.4601551,1.98959236 C13.6337215,1.81602601 13.9031459,1.79674086 14.098014,1.93173691 L14.1672619,1.98959236 C14.362524,2.18485451 14.362524,2.501437 14.1672619,2.69669914 L14.1672619,2.69669914 L8.864,8.00014575 L14.1672619,13.3033009 C14.362524,13.498563 14.362524,13.8151455 14.1672619,14.0104076 C13.9719997,14.2056698 13.6554173,14.2056698 13.4601551,14.0104076 L8.157,8.70714575 L2.85355339,14.0104076 C2.67998704,14.183974 2.41056264,14.2032591 2.2156945,14.0682631 L2.14644661,14.0104076 C1.95118446,13.8151455 1.95118446,13.498563 2.14644661,13.3033009 L2.14644661,13.3033009 L7.45,8.00014575 L2.14644661,2.69669914 C1.95118446,2.501437 1.95118446,2.18485451 2.14644661,1.98959236 C2.34170876,1.79433021 2.65829124,1.79433021 2.85355339,1.98959236 Z"></path>
                        </svg>
                      </i>
                    </span>
                  </div>
                </Tooltip>
              );
            })}
          </div>
          {!selectInfoArr.length ? (
            <div className={styles['placeholder']}>Select</div>
          ) : (
            <i
              className={styles['clear-icon']}
              onClick={$event => clearSelect($event)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16">
                <path
                  fillRule="evenodd"
                  d="M8,2 C11.3137085,2 14,4.6862915 14,8 C14,11.3137085 11.3137085,14 8,14 C4.6862915,14 2,11.3137085 2,8 C2,4.6862915 4.6862915,2 8,2 Z M10.5924919,5.27303573 C10.4094355,5.1521972 10.1606887,5.17236516 9.99956233,5.33352414 L9.99956233,5.33352414 L8.00023568,7.33325477 L6.00047136,5.33349045 C5.81628967,5.14930876 5.51767215,5.14930876 5.33349045,5.33349045 L5.33349045,5.33349045 L5.27301564,5.40754038 C5.1522078,5.59059052 5.17239885,5.83931011 5.33355782,6.00040399 L5.33355782,6.00040399 L7.33372614,7.99976432 L5.33352414,9.99956232 L5.33352414,9.99956232 L5.27306194,10.0735738 C5.15220491,10.2566181 5.17234775,10.5053668 5.33349045,10.6665095 L5.33349045,10.6665095 L5.40750807,10.7269643 C5.5905645,10.8478028 5.83931125,10.8276348 6.00043768,10.6664759 L6.00043768,10.6664759 L8.00023568,8.66627386 L9.99959601,10.6664422 L9.99959601,10.6664422 L10.0736337,10.726932 C10.2566595,10.8477768 10.5053831,10.827636 10.6665095,10.6665095 C10.8506912,10.4823279 10.8506912,10.1837103 10.6665095,9.99952864 L10.6665095,9.99952864 L8.66674523,7.99976432 L10.6664759,6.00043767 L10.6664759,6.00043767 L10.7269381,5.92642616 C10.8477951,5.74338194 10.8276522,5.49463316 10.6665095,5.33349045 L10.6665095,5.33349045 Z"></path>
              </svg>
            </i>
          )}
          <i className={styles['select-arrow']}>
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              xmlns="http://www.w3.org/2000/svg">
              <path d="M8 9.18934L4.03033 5.21967C3.73744 4.92678 3.26256 4.92678 2.96967 5.21967C2.67678 5.51256 2.67678 5.98744 2.96967 6.28033L7.46967 10.7803C7.76256 11.0732 8.23744 11.0732 8.53033 10.7803L13.0303 6.28033C13.3232 5.98744 13.3232 5.51256 13.0303 5.21967C12.7374 4.92678 12.2626 4.92678 11.9697 5.21967L8 9.18934Z" />
            </svg>
          </i>
        </div>
      </Dropdown>
    </div>
  );
};

export default forwardRef(Cascader);
