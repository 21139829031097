import React, {
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { Modal, Search } from 'shopee-ui-react';
import styles from './index.module.scss';
import Cascader from '@components/Cascader';
import UserSearch from '../UserSearch';

export interface HrFunctionEditModalProps {
  type: 'add' | 'edit';
  teamOptions: any[];
  onConfirm: () => void;
}

export default forwardRef(
  (
    { type = 'add', teamOptions = [], onConfirm }: HrFunctionEditModalProps,
    ref: any
  ) => {
    const cascaderRef = useRef<{
      getRealSelectedValues: () => any[];
    }>(null);

    const [visible, setVisible] = useState(false);
    const [teamIds, setTeamIds] = useState<any[]>([]);
    const [user, setUser] = useState('');
    const [validateInfo, setValidateInfo] = useState({
      hrUser: true,
      teamScope: true,
    });

    useImperativeHandle(
      ref,
      () => {
        return {
          open: ({ teamIds, user }: { teamIds: any[]; user: string }) => {
            setVisible(true);
            setUser(user);
            setTeamIds(teamIds);
            setValidateInfo({
              hrUser: true,
              teamScope: true,
            });
          },
          close: () => setVisible(false),
          getValue: () => {
            return {
              teamIds: cascaderRef.current?.getRealSelectedValues(),
              user,
            };
          },
        };
      },
      [user, teamIds]
    );

    const handleConfirm = () => {
      if (user && teamIds.length) {
        onConfirm();
        return;
      }
      let info = {
        ...validateInfo,
      };
      if (!user) {
        info = {
          ...validateInfo,
          hrUser: false,
        };
      }
      if (!teamIds.length) {
        info = {
          ...info,
          teamScope: false,
        };
      }
      setValidateInfo(info);
    };

    const handleTeamIdChange = (values: any[]) => {
      setTeamIds(values);
      if (values.length && !validateInfo.teamScope) {
        setValidateInfo({
          ...validateInfo,
          teamScope: true,
        });
      }
    };

    const handleUserChange = (value: string) => {
      setUser(value);
      if (value && !validateInfo.hrUser) {
        setValidateInfo({
          ...validateInfo,
          hrUser: true,
        });
      }
    };

    return (
      <Modal
        className={styles.user_modal}
        visible={visible}
        title={type === 'add' ? 'Add HR User' : 'Edit HR User'}
        onCancel={() => setVisible(false)}
        confirmText={type === 'add' ? 'Submit' : 'Save'}
        onConfirm={handleConfirm}>
        <div className={styles.form_item}>
          <div className={styles.label}>
            <span className={styles.required}>*</span>
            HR User
          </div>
          <UserSearch
            error={!validateInfo.hrUser}
            user={user}
            useFuzzy={true}
            disabled={type === 'edit'}
            onChange={handleUserChange}
          />
          {!validateInfo.hrUser ? (
            <div className={styles.valid}>This filed is required</div>
          ) : null}
        </div>

        <div className={styles.form_item}>
          <div className={styles.label}>
            <span className={styles.required}>*</span>
            Team Scope
          </div>
          <Cascader
            error={!validateInfo.teamScope}
            ref={cascaderRef}
            options={teamOptions}
            setSelectValueArr={handleTeamIdChange}
            selectedValueArr={teamIds}
          />
          {!validateInfo.teamScope ? (
            <div className={styles.valid}>This filed is required</div>
          ) : null}
        </div>
      </Modal>
    );
  }
);
