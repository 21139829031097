export enum CalendarRange {
  ThirtyDays = '30',
  FourteenDays = '14',
  SevenDays = '7',
}

export const CalendarRangeMap: any = {
  0: CalendarRange.SevenDays,
  1: CalendarRange.FourteenDays,
  2: CalendarRange.ThirtyDays,
};

export const selectAllValue = -1;
