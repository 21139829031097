import React, { useMemo, useState } from 'react';
import { Select } from 'shopee-ui-react';

interface MultipleSelectProps {
  value?: any[];
  options?: {
    value: number | string;
    label: string;
  }[];
  onChange?: (value: any[]) => void;
}

const MultipleSelect = ({
  value = [],
  options = [],
  onChange,
}: MultipleSelectProps) => {
  console.log('value', value);
  const selectAllKey = 'multipleSelectAllKey';
  const [isFiltering, setIsFiltering] = useState(false);

  const InnerMultipleValue = useMemo(() => {
    if (options.length && value.length === options.length) {
      console.log([...value, selectAllKey]);
      return [...value, selectAllKey];
    }
    return value;
  }, [value, options]);

  const handleValueChange = (currentValue: any) => {
    const pastIsSelectAll = value.length === options.length;
    if (currentValue.includes(selectAllKey) && !pastIsSelectAll) {
      onChange && onChange(options.map(option => option.value));
    } else if (!currentValue.includes(selectAllKey) && pastIsSelectAll) {
      onChange && onChange([]);
    } else {
      const selectAllKeyIndex = currentValue.indexOf(selectAllKey);
      if (selectAllKeyIndex > -1) {
        currentValue.splice(selectAllKeyIndex, 1);
      }
      onChange && onChange(currentValue);
    }
  };

  const handleFilter = (value: any) => {
    if (value) {
      setIsFiltering(true);
      return;
    }
    setIsFiltering(false);
  };

  return (
    <Select
      value={InnerMultipleValue}
      multiple
      placeholder="Select"
      maxLine={1}
      filterable
      onFilter={handleFilter}
      onChange={handleValueChange}
      clearable>
      {options.length && !isFiltering ? (
        <Select.Option value={selectAllKey}>Select All</Select.Option>
      ) : null}
      {options.map((option, index) => (
        <Select.Option
          key={index}
          value={option.value}>
          {option.label}
        </Select.Option>
      ))}
    </Select>
  );
};

export default MultipleSelect;
