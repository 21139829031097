import React, { useEffect, useMemo, useRef, useState } from 'react';
import styles from './index.module.scss';
import {
  Button,
  DatePicker,
  Modal,
  Search,
  Select,
  Table,
  Toast,
  Tooltip,
} from 'shopee-ui-react';
import dayjs from 'dayjs';
import { AttendanceReportListItem } from '@api/type';
import MultipleSelect from '@components/MultipleSelect';
import Cascader from '@components/Cascader';
import EditModal from './components/EditModal';
import UserSearch from './components/UserSearch';
import {
  addWhiteUser,
  deleteWhiteUser,
  getWhitelist,
  getWhitelistTeamScope,
} from '@api/hrFunction';
import { HrFunctionItem } from '@api/hrFunction/type';
import { useNavigate } from 'react-router-dom';
import { formatDate, handleCascaderOptions } from '@util/index';

interface refProps {
  getRealSelectedValues: () => any[];
}

interface modalRefProps {
  open: ({ teamIds, user }: { teamIds: any[]; user: string }) => void;
  close: () => void;
  getValue: () => { user: string; teamIds: number[] };
}

const getDefaultParams = (): any => {
  return {
    keyword: '',
    teamIds: [],
    pageNum: 1,
    pageSize: 10,
  };
};

const HrFunction = () => {
  const ref = useRef<refProps>(null);
  const modalRef = useRef<modalRefProps>(null);
  const isInit = useRef<boolean>(false);
  const navigate = useNavigate();

  const [listParams, setListParams] = useState(getDefaultParams());

  const [tableData, setTableData] = useState<HrFunctionItem[]>([]);
  const [pageInfo, setPageInfo] = useState({
    total: 0,
    current: 1,
    pageSize: 10,
  });
  const [tableLoading, setTableLoading] = useState(false);

  const [teamValue, setTeamValue] = useState<any[]>([]);
  const [teamOptions, setTeamOptions] = useState([]);

  const renderText = (text: string) => (
    <Tooltip
      content={text || '-'}
      placement="topLeft">
      <span className={styles.ellipsis}>{text || '-'}</span>
    </Tooltip>
  );

  const columns: any = useMemo(() => {
    return [
      {
        title: 'HR User',
        dataIndex: 'hrName',
        ellipsis: true,
        width: 320,
        render: (text: string, row: HrFunctionItem) => {
          return renderText(`${text} (${row.hrEmail})`);
        },
      },
      {
        title: 'Team Scope',
        dataIndex: 'teamScopeList',
        ellipsis: true,
        width: 500,
        render: (list: any) => {
          const html = list.map((item: any) => item.teamPath).join(' / ');
          return (
            <Tooltip
              content={
                <div dangerouslySetInnerHTML={{ __html: html || '-' }}></div>
              }
              placement="topLeft">
              <div
                className={`${styles.ellipsis} ${styles.three}`}
                dangerouslySetInnerHTML={{ __html: html || '-' }}></div>
            </Tooltip>
          );
        },
      },
      {
        title: 'Updated By',
        dataIndex: 'updatedByName',
        ellipsis: true,
        width: 320,
        render: (text: string, row: HrFunctionItem) => {
          return renderText(
            `${text}${row.updatedByEmail ? ` (${row.updatedByEmail})` : ''}`
          );
        },
      },
      {
        title: 'Updated On',
        dataIndex: 'updatedOn',
        ellipsis: true,
        width: 150,
        render: (text: string) => {
          return renderText(formatDate(Number(text) * 1000));
        },
      },
      {
        title: 'Action',
        dataIndex: 'duration',
        ellipsis: true,
        width: 120,
        fixed: 'right',
        render: (text: string, row: HrFunctionItem) => {
          return (
            <>
              <div>
                <Button
                  onClick={() => handleClickDetail(row.id)}
                  className={styles.action_button}
                  type="link">
                  View Details
                </Button>
              </div>
              <div>
                <Button
                  onClick={() => handleClickDelete(row.id)}
                  className={styles.action_button}
                  type="link">
                  Delete
                </Button>
              </div>
            </>
          );
        },
      },
    ];
  }, []);

  const getHrFunctionList = async (options: any) => {
    setTableLoading(true);
    const { code, data, message } = await getWhitelist(options);
    if (code !== 0) {
      Toast.error(message);
      setTableLoading(false);
      return;
    }
    setTableData(data.hrList);
    setPageInfo({
      ...pageInfo,
      total: data.pageInfo.total,
      current: data.pageInfo.pageNum,
      pageSize: data.pageInfo.pageSize,
    });
    setTableLoading(false);
  };

  const getTeamInfo = async () => {
    const { code, data } = await getWhitelistTeamScope();
    if (code !== 0) {
      Toast.error('Get Select Info Error');
      return;
    }
    setTeamOptions(handleCascaderOptions(data));
  };

  const handleSearchKeyChange = (value: any) => {
    setPageInfo({
      ...pageInfo,
      current: 1,
    });
    setListParams({
      ...listParams,
      keyword: value,
      pageNum: 1,
    });
  };

  const handleTeamIdChange = (value: any[]) => {
    setTeamValue(value);
  };

  const handleModalConfirm = async () => {
    const value = modalRef.current?.getValue();
    const { code, message } = await addWhiteUser({
      hrEmail: value?.user.trim() || '',
      teamIds: value?.teamIds || [],
    });
    if (code === 9002) {
      Toast.error(
        'HR user already exists within the system. Kindly make edits if required.'
      );
    } else if (code !== 0) {
      Toast.error(message);
    } else {
      Toast.success('HR user added successfully');
      const options = {
        ...listParams,
        pageNum: 1,
      };
      setListParams(options);
      setPageInfo({
        ...pageInfo,
        current: 1,
      });
      getHrFunctionList(options);
      modalRef.current?.close();
    }
  };

  const handlePageChange = (current: number) => {
    const options = {
      ...listParams,
      pageNum: current,
    };
    setListParams(options);
    setPageInfo({
      ...pageInfo,
      current,
    });
    getHrFunctionList(options);
  };

  const handlePageSizeChange = (pageSize: number) => {
    const options = {
      ...listParams,
      pageNum: 1,
      pageSize,
    };
    setListParams(options);
    setPageInfo({
      ...pageInfo,
      pageSize,
      current: 1,
    });
    getHrFunctionList(options);
  };

  const handleReset = () => {
    const options = getDefaultParams();
    setListParams(options);
    setTeamValue([]);
  };

  const handleAddHrUserButtonClick = () => {
    modalRef.current?.open({
      teamIds: [],
      user: '',
    });
  };

  const handleClickDetail = (id: number) => {
    navigate(`/hr-function/detail/${id}`);
  };

  const handleClickDelete = (id: number) => {
    const modal: any = Modal.confirm({
      title: 'Delete HR User',
      content:
        'Deleting this HR user would remove all HR data access maintained for this user within the Attendance system. Confirm?',
      onConfirm: () => {
        return new Promise(resolve => {
          modal.update({ isConfirmLoading: true });
          deleteWhiteUser({ hrUserId: id }).then(({ code, message }) => {
            if (code !== 0) {
              Toast.error(message);
            } else {
              Toast.success('HR user deleted successfully');
              getHrFunctionList(listParams);
            }
            resolve();
          });
        });
      },
    });
  };

  useEffect(() => {
    getTeamInfo();
  }, []);

  useEffect(() => {
    if (!isInit.current) {
      isInit.current = true;
      return;
    }
    setPageInfo({
      ...pageInfo,
      current: 1,
    });
    setListParams({
      ...listParams,
      teamIds: teamValue.length ? ref.current?.getRealSelectedValues() : [],
      pageNum: 1,
    });
  }, [teamValue]);

  useEffect(() => {
    getHrFunctionList({
      ...listParams,
      keyword: listParams.keyword.trim(),
    });
  }, [listParams]);

  return (
    <div className={styles.function_wrapper}>
      <div className={styles.function_title}>HR Data Access</div>
      <div className={styles.tip}>
        The configuration on this page determines the HR users&#39; data scope
        for <i>Attendance Report</i> and <i>Search Staff</i> within the
        Attendance Web.
      </div>
      <div className={styles.filter_wrapper}>
        <div className={styles.filter_item}>
          <div className={styles.filter_label}>HR User</div>
          <UserSearch
            user={listParams.keyword}
            onChange={handleSearchKeyChange}
          />
        </div>

        <div className={styles.filter_item}>
          <div className={styles.filter_label}>Team Scope</div>
          <Cascader
            ref={ref}
            options={teamOptions}
            setSelectValueArr={handleTeamIdChange}
            selectedValueArr={teamValue}
          />
        </div>

        <div className={styles.filter_item}>
          <Button
            className={styles.reset}
            onClick={handleReset}>
            Reset
          </Button>
        </div>
      </div>

      <div className={styles.table_wrapper}>
        <div className={styles.table_header}>
          <div className={styles.table_title}>{pageInfo.total} HR Users</div>
          <Button
            onClick={handleAddHrUserButtonClick}
            type="primary"
            icon="add">
            Add HR User
          </Button>
        </div>
        <Table
          loading={tableLoading}
          dataSource={tableData}
          columns={columns}
          pagination={{
            onChange: handlePageChange,
            onSizeChange: handlePageSizeChange,
            ...pageInfo,
            hideOnSinglePage: false,
            layout: 'pages,sizes,jumper',
          }}
          scroll={{ x: 1300 }}
        />
      </div>

      <EditModal
        ref={modalRef}
        type="add"
        teamOptions={teamOptions}
        onConfirm={handleModalConfirm}
      />
    </div>
  );
};

export default HrFunction;
