import React, { useState } from 'react';
import styles from './index.module.scss';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { Search } from 'shopee-ui-react';
import { serachStaffs } from '@api/index';
import { debounce } from 'lodash-es';

export interface MenuListItem {
  path?: string;
  label?: string;
  iconClassName?: string;
  children?: {
    path?: string;
    label?: string;
    iconClassName?: string;
  }[];
}

interface SidebarProps {
  hrPermission?: boolean;
  menuList?: MenuListItem[];
}

const Sidebar = ({ menuList = [], hrPermission }: SidebarProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [staffValue, setStaffValue] = useState();
  const [staffList, setStaffList] = useState<
    {
      pk: number;
      name: string;
    }[]
  >([]);

  const handleValueChange = async (info: any) => {
    const { value } = info;
    console.log(value);
    if (!value) {
      setStaffList([]);
      return;
    }
    console.log(location.search);
    const params = new URLSearchParams(location.search);
    console.log(params.get('staffs')?.split(','));
    setLoading(true);
    const res = await serachStaffs({ keyword: value });
    setStaffList(res.array);
    setLoading(false);
  };

  const debounceHandleValueChange = debounce(handleValueChange, 300);

  const handleSearch = (value: any) => {
    if (!loading && staffList.length > 0) {
      navigate(
        `/staff-attendance?staffs=${staffList.map(item => item.pk).join(',')}`
      );
    }
  };

  const handleStaffSelect = (value: any) => {
    console.log(value);
    navigate(
      `/staff-attendance?staffs=${
        staffList.filter(item => item.name === value)[0].pk
      }`
    );
  };

  return (
    <div className={styles.sidebar}>
      <div className={styles.sidebar_wrapper}>
        {menuList.map((menu, index) => {
          if ((menu.children && menu.children.length) || menu.path) {
            return (
              <div
                key={index}
                className={styles.menu_wrapper}>
                <div className={styles.sidebar_title}>
                  {menu.iconClassName ? (
                    <span
                      className={`${menu.iconClassName} ${styles.icon}`}></span>
                  ) : null}
                  <span>{menu.label}</span>
                </div>
                {menu.children &&
                  menu.children.map(child => {
                    return (
                      <NavLink
                        key={child.path}
                        to={child.path || ''}
                        className={({ isActive }) =>
                          `${styles.menu_item} ${isActive ? styles.acitve : ''}`
                        }>
                        {child.label}
                      </NavLink>
                    );
                  })}
              </div>
            );
          }
        })}
      </div>
      {hrPermission ? (
        <Search
          className={styles.search}
          defaultValue={{
            value: '',
            label: '',
          }}
          placeholder="Search Staff"
          loading={loading}
          onChange={debounceHandleValueChange}
          onSearch={handleSearch}>
          {staffList.map((item, index) => (
            <Search.Option
              onSelect={handleStaffSelect}
              key={index}
              value={item.name}>
              {item.name}
            </Search.Option>
          ))}
        </Search>
      ) : null}
    </div>
  );
};

export default Sidebar;
